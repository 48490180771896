import axios from 'axios';

const url = 'https://gsr-dt-app-d002.azurewebsites.net';
// const url = 'http://localhost:8080';
// const url = 'https://gsr-dt-api-p002.azurewebsites.net';

const NewApi = axios.create({
	baseURL: url,
	withCredentials: true,
});

NewApi.defaults.withCredentials = true;

NewApi.interceptors.response.use(
	function (response) {
		/*
        http status가 200인 경우
        응답 성공 직전 호출됩니다. 
        .then() 으로 이어집니다.
    */
		return response;
	},

	function (error) {
		/*
        http status가 200이 아닌 경우
        응답 에러 직전 호출됩니다.
        .catch() 으로 이어집니다.    
    */
		// apiError(error?.response?.data);
		return Promise.reject(error);
	}
);

NewApi.defaults.headers.post['Content-Type'] = 'application/json';

export default NewApi;
