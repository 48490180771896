import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMsal } from '@azure/msal-react';
import {
	postStoreDistance,
	postStoreInfo,
	storeDetailReset,
	postFixedDistance,
	postStoreSearch,
	searchReset,
	areaInfoReset,
} from '../../reducers/store';
import { markerImage, StoreMoreListWrap } from './';
import { use100vh } from 'react-div-100vh';
// icon
import { ReactComponent as Search } from '../../assets/search.svg';
import TabletOrMoblieSidebar from '../../components/sidebar/TabletOrMobileSidebar';
import { RiPushpin2Fill } from 'react-icons/ri';
import { ReactComponent as Close } from '../../assets/close.svg';
// hooks
import { OnIsTabletOrMobile } from '../../hook/widthSize';
import { AiOutlineExclamation } from 'react-icons/ai';
// moment
import moment from 'moment';
import 'moment/locale/ko';
// components
import BrandDistanceList from '../../components/brandDistanceList';
import Select from '../../components/select';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import NavigateBeforeIcon from '@mui/icons-material/ArrowBack';

import { userInfo } from '../../reducers/user';


const { naver } = window;

function formatToIntegerWithCommas(numberString) {
	if (numberString === null || Math.floor(parseFloat(numberString)) === 0) {
	  return '-';
	}else{
	  let number = parseFloat(numberString);
	  let integerNumber = Math.floor(number);
	  let formattedNumber = integerNumber.toLocaleString('en-US');
	  return formattedNumber;
	}
}


// @ts-ignore
const Circle = new naver.maps.Circle({
	// map: map.current,
	// center: new naver.maps.LatLng(store.y, store.x),
	// radius: codeFilterStorage.distance,
	fillOpacity: 0.7,
	strokeOpacity: 0.9,
	fillColor: '#3e85e8',
	strokeColor: '#3e85e8',
});

const MobileHomePage = ({ setSnackbarMessage }) => {
	const { accounts } = useMsal();

	const map = useRef();
	const dispatch = useDispatch();
	const [active, setActive] = useState('competitor');
	const {
		storeList,
		searchStoreList,
		storeInfo,
		storeReviewList,
		fixedStoreFilterList,
		storekeywordStat,
    	areaInfo,
    	areaInfoDetail,
		reviewDate,
		status,
	} = useSelector((state) => state.store);
	const isTabletOrMobile = OnIsTabletOrMobile();
	const listEl = useRef(null);

	const height = use100vh();

	// active store more
	const [storeMore, setStoreMore] = useState({
		open: false,
		child: null,
		position: null,
	});

	const userInfoData = useSelector((state) => state.user);

	// info
	const [infoDetailActive, setInfoDetailActive] = useState('competitor');
	const infoEl = useRef(null);
	// const infoDefaultEl = useRef(null);
	const infoDetailEl = useRef(null);

	// 스크롤 옵셋
	const [searchOffset, setSearchOffset] = useState(0);
	// mode
	const [searchMode, setSearchMode] = useState(false);
	const [detailMode, setDetailMode] = useState(false);

	const [activeStore, setActiveStore] = useState({
		open: false,
		data: null,
	});

	// 맵 필터 코드
	const [codeFilter, setCodeFilter] = useState(null);

	// 반경
	const [distance, setDistance] = useState('500');

	// 마커 정보
	const markers = useRef([]);

	// 검색 값
	const [searchValue, setSearchValue] = useState('');

	/** 매장 정보, 반경 정보 로컬 스토리지 */
	useEffect(() => {
		const mvpStoreLocalStorage = localStorage.getItem(
			'MVP-STORE-CONTROLLER-V2.2'
		);

		if (!mvpStoreLocalStorage) {
			localStorage.setItem(
				'MVP-STORE-CONTROLLER-V2.2',
				JSON.stringify({
					mapFilter: {
						C1000: ['C1001', 'C1002', 'C1003', 'C1004'],
						C2000: ['C2001', 'C2003', 'C2005', 'C2008'],
						C3000: null,
						C4000: null,
						C5000: null,
					},
					distance: '500',
				})
			);
			setCodeFilter({
				C1000: ['C1001', 'C1002', 'C1003', 'C1004'],
				C2000: ['C2001', 'C2003', 'C2005', 'C2008'],
				C3000: null,
				C4000: null,
				C5000: null,
			});
			setDistance('500');
		} else {
			const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

			setCodeFilter(mvpStoreLocalStorageJson.mapFilter);
			setDistance(mvpStoreLocalStorageJson.distance);
		}
	}, []);

	/** 사용자 위치 정보 설정 */
	useEffect(() => {
		const mvpStoreLocalStorage = localStorage.getItem(
			'MVP-STORE-CONTROLLER-V2.2'
		);

		const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

		map.current = new naver.maps.Map('map', {
			center: new naver.maps.LatLng(37.501921162780974, 127.03726398429752),
			zoom: 18,
		});

		if (navigator?.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const golocationPosition = new naver.maps.LatLng(
						position.coords.latitude,
						position.coords.longitude
					);

					map.current.setCenter(golocationPosition);

					dispatch(
						postStoreDistance({
							lat: position.coords.latitude,
							lng: position.coords.longitude,
							distance: mvpStoreLocalStorageJson.distance,
							account: accounts[0]['username'],
						})
					);
				},
				(error) => {
					// console.log('위치 정보 미동의');
					dispatch(
						postStoreDistance({
							lat: 37.501921162780974,
							lng: 127.03726398429752,
							distance: mvpStoreLocalStorageJson.distance,
							account: accounts[0]['username'],
						})
					);
					// console.error(error);
				},
				{
					enableHighAccuracy: false,
					maximumAge: 0,
					timeout: Infinity,
				}
			);
		} else {
			console.log('GPS를 지원하지 않습니다');

			dispatch(
				postStoreDistance({
					lat: 37.501921162780974,
					lng: 127.03726398429752,
					distance: mvpStoreLocalStorageJson.distance,
					account: accounts[0]['username'],
				})
			);
		}
	}, [accounts, dispatch]);

	/** 지도 반경 원 설정 */
	const setCircle = useCallback((y, x) => {
		Circle.setCenter(naver.maps.LatLng(y, x));
		Circle.setRadius(distance);
		Circle.setMap(map.current);
	}, [distance]);

	/** 매장 활성화  */
	const onClickStore = useCallback((store) => {
		dispatch(
			postStoreInfo({
				account: accounts[0]['username'],
				id: store.id,
			})
		);
		dispatch(
			postFixedDistance({
				category: store['category'],
				lat: store.y,
				lng: store.x,
				distance,
				name: store['name'],
				account: accounts[0]['username'],
				codeFilter,
			})
		);
		setActiveStore({ open: true, data: store });
		setCircle(store.y, store.x);
		map.current.setCenter(naver.maps.LatLng(store.y, store.x));
	}, [accounts, codeFilter, dispatch, distance, setCircle]);

	useEffect(() => {
		if (storeList) {
			if (markers.current.length > 0) {
				markers.current.forEach((marker) => {
					marker['marker'].setMap(null);
				});
				markers.current = [];
			}

			const proj = map.current.getProjection();

			const overlap = [];
			storeList?.forEach((store, i) => {
				const coord = new naver.maps.LatLng(store.y, store.x);
				const markerOffset = proj.fromCoordToOffset(coord);

				if (
					(store['category'] === '편의점' &&
						codeFilter?.['C1000'] &&
						codeFilter['C1000'].includes(store['sub_category_code'])) ||
					(store['category'] === '슈퍼,마트' &&
						codeFilter?.['C2000'] &&
						codeFilter['C2000'].includes(store['sub_category_code'])) ||
					(store['category'] === '반찬가게' && codeFilter?.['C3000']) ||
					(store['category'] === '주유소' &&
						codeFilter?.['C4000'] &&
						codeFilter['C4000'].includes(store['sub_category_code'])) ||
					(store['category'] === '정육점' && codeFilter?.['C5000'])
				) {
					if (overlap.length <= 0) {
						overlap.push({
							...store,
							coord,
							offset: markerOffset,
							child: [],
						});
					} else {
						const resultIndex = overlap.findIndex(
							(m) =>
								Math.abs(m.offset.x - markerOffset.x) <= 35 &&
								Math.abs(m.offset.y - markerOffset.y) <= 35
						);
						if (resultIndex !== -1) {
							overlap[resultIndex].child.push({ ...store });
						} else {
							overlap.push({
								...store,
								coord,
								offset: markerOffset,
								child: [],
							});
						}
					}
				}
			});

			overlap.forEach((store) => {
				const content = markerImage(
					store['category'],
					store['sub_category'],
					store['name'],
					store['dst'],
					codeFilter,
					store.name,
					store.child,
					store.rfc_region_cd,
					store.oper_region_cd,
					userInfoData?.team_mng_yn,
					userInfoData?.rfc_yn,
					userInfoData?.rfc_adm_yn,
					userInfoData?.region_cd,
				);

				const marker = new naver.maps.Marker({
					map: map.current,
					position: store.coord,
					icon: {
						content: content,
						size: new naver.maps.Size(30, 30),
						anchor: new naver.maps.Point(20, 45),
					},
				});

				markers.current = [...markers.current, { ...store, marker }];

				if (marker) {
					naver.maps.Event.addListener(marker, 'click', (e) => {
						if (store.child.length <= 0) {
							// 숨김 마커 없을 때,
							onClickStore(store);
							setStoreMore({
								open: false,
								child: null,
								position: null,
							});
						} else {
							// 숨김 마커 있을 때,
							deleteCircle();
							map.current.setCenter(naver.maps.LatLng(store.y, store.x));

							const marker = document.getElementById(`${store.name}`);
							if (activeStore?.data) {
								marker && marker.parentElement.classList.remove('active');
							}

							const rect = marker.getBoundingClientRect();
							onClickStore(store);
							setStoreMore({
								open: true,
								child: store.child,
								position: { bottom: rect.bottom, left: rect.left },
							});
							// setActiveStore({ open: true, data: store });
						}
					});
				}
			});
		}
	}, [storeList, distance, codeFilter, accounts, activeStore, onClickStore]);

	/** 화면 크기 변화 감지 */
	useEffect(() => {
		const sizeEvent = naver.maps.Event.addListener(
			map.current,
			'size_changed',
			(e) => {
				map.current.autoResize();
			}
		);
		return () => {
			naver.maps.Event.removeListener(sizeEvent);
		};
	}, []);

	/** 매장 상세 닫기 */
	const onClickStoreDetailClose = useCallback(() => {
		setActiveStore({ open: false, data: null });
		deleteCircle();
		dispatch(storeDetailReset());
		dispatch(areaInfoReset());
		
		if (activeStore?.data) {
			const marker = document.getElementById(`${activeStore.data.name}`);
			marker && marker.parentElement.classList.remove('active');
		}
	}, [activeStore.data, dispatch]);

	/** 지도 줌 이벤트 */
	const onChangeZoom = useCallback((e) => {
		setStoreMore({
			open: false,
			child: null,
			position: null,
		});
		if (e <= 10) {
			if (markers.current.length > 0) {
				markers.current.forEach((marker) => {
					marker['marker'].setMap(null);
				});
				markers.current = [];
			}
			onClickStoreDetailClose();
		} else if (!Circle.getMap()) {
			const position = map.current.getCenter();
			dispatch(
				postStoreDistance({
					lat: position.y,
					lng: position.x,
					distance,
					account: accounts[0]['username'],
				})
			);
		}
	}, [accounts, distance, dispatch, onClickStoreDetailClose]);

	/** 지도 드래그 이벤트 */
	const onChangeDragend = useCallback((e) => {
		if (map.current.getZoom() > 10 && !Circle.getMap()) {
			const position = map.current.getCenter();

			dispatch(
				postStoreDistance({
					lat: position.y,
					lng: position.x,
					distance,
					account: accounts[0]['username'],
				})
			);
		}
	}, [accounts, distance, dispatch]);

	/** 지도 줌 & 드래그 변화 감지 */
	useEffect(() => {
		const dragStartEvent = naver.maps.Event.addListener(
			map.current,
			'dragstart',
			onDragStart
		);
		const centerEvent = naver.maps.Event.addListener(
			map.current,
			'dragend',
			onChangeDragend
		);
		const zoomEvent = naver.maps.Event.addListener(
			map.current,
			'zoom_changed',
			onChangeZoom
		);
		const sizeEvent = naver.maps.Event.addListener(
			map.current,
			'size_changed',
			onChangeSize
		);

		return () => {
			naver.maps.Event.removeListener(centerEvent);
			naver.maps.Event.removeListener(zoomEvent);
			naver.maps.Event.removeListener(sizeEvent);
			naver.maps.Event.removeListener(dragStartEvent);
		};
	}, [accounts, distance, onChangeDragend, onChangeZoom]);

	const onChangeSize = (e) => {
		setStoreMore({
			open: false,
			child: null,
			position: null,
		});
		map.current.autoResize();
	};

	const onDragStart = (e) => {
		setStoreMore({
			open: false,
			child: null,
			position: null,
		});
	};

	/** 지도 반경 원 제거 */
	const deleteCircle = (y, x) => {
		Circle.setMap(null);
	};

	/** 매장 활성화 ClassName 지정 */
	useEffect(() => {
		if (activeStore.data) {
			const marker = document.getElementById(`${activeStore['data']['name']}`);
			marker && marker.parentElement.classList.add('active');
		}
	}, [activeStore, storeList]);

	useEffect(() => {
		map.current.autoResize();
	}, [searchMode, detailMode]);

	const onClickFilterButton =
		({ mainCode, subCode }) =>
		(e) => {
			e.stopPropagation();
			const mvpStoreLocalStorage = localStorage.getItem(
				'MVP-STORE-CONTROLLER-V2.2'
			);
			const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

			let newMapFilter = {};

			if (mvpStoreLocalStorageJson.mapFilter?.[mainCode]) {
				if (subCode) {
					newMapFilter = {
						...mvpStoreLocalStorageJson.mapFilter,
						[mainCode]: mvpStoreLocalStorageJson.mapFilter[mainCode].includes(
							subCode
						)
							? mvpStoreLocalStorageJson.mapFilter[mainCode]?.length > 1
								? mvpStoreLocalStorageJson.mapFilter[mainCode].filter(
										(s) => s !== subCode
								  )
								: null
							: [...mvpStoreLocalStorageJson.mapFilter[mainCode], subCode],
					};
				} else {
					newMapFilter = { ...mvpStoreLocalStorageJson.mapFilter };
					delete newMapFilter[mainCode];
				}
			} else {
				if (!subCode) {
					newMapFilter = {
						...mvpStoreLocalStorageJson.mapFilter,
						[mainCode]:
							mainCode === 'C1000'
								? ['C1001', 'C1002', 'C1003', 'C1004', 'C1999']
								: mainCode === 'C2000'
								? [
										'C2001',
										'C2003',
										'C2005',
										'C2006',
										'C2008',
										'C2011',
										'C2012',
										'C2999',
								  ]
								: mainCode === 'C3000'
								? ['C3001']
								: mainCode === 'C4000'
								? ['C4001', 'C4002', 'C4003', 'C4004', 'C4005', 'C4999']
								: mainCode === 'C5000'
								? ['C5001']
								: null,
					};
				} else {
					newMapFilter = {
						...mvpStoreLocalStorageJson.mapFilter,
						[mainCode]: [subCode],
					};
				}
			}

			const newMvpStoreController = {
				...mvpStoreLocalStorageJson,
				mapFilter: newMapFilter,
			};

			localStorage.setItem(
				'MVP-STORE-CONTROLLER-V2.2',
				JSON.stringify(newMvpStoreController)
			);

			setCodeFilter(newMapFilter);
		};

	/** 지도 반경 변경 기능 */
	const onClickDistanceButton = (distance) => () => {
		const newCodeFilter = {
			mapFilter: codeFilter,
			distance,
		};

		if (!activeStore.open) {
			const position = map.current.getCenter();
			dispatch(
				postStoreDistance({
					lat: position.y,
					lng: position.x,
					distance,
					account: accounts[0]['username'],
				})
			);
		} else {
			const position = Circle.getCenter();
			Circle.setRadius(distance);
			dispatch(
				postFixedDistance({
					lat: position.y,
					lng: position.x,
					distance,
					account: accounts[0]['username'],
					name: activeStore['data']['name'],
					codeFilter,
				})
			);
		}

		localStorage.setItem(
			'MVP-STORE-CONTROLLER-V2.2',
			JSON.stringify(newCodeFilter)
		);

		setDistance(distance);
	};

	const onSearch = (type) => () => {
		const category = [];

		Object.entries(codeFilter).map(([key, value]) => {
			if (value?.length > 0) {
				category.push({
					code: key,
					sub_code: value,
				});
			}
			return null;
		});

		if (type === 'scroll') {
			setSearchOffset((prev) => prev + 30);
		} else {
			setSearchOffset(0);
			listEl.current.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}

		dispatch(
			postStoreSearch({
				storeName: searchValue,
				account: accounts[0]['username'],
				openSnackbar: setSnackbarMessage,
				category,
				offset: type === 'scroll' ? searchOffset + 30 : 0,
			})
		);
	};

	/** 검색어 이벤트 */
	const onChangeSearchValue = (e) => {
		if (e.target.value.trim() === '') {
			dispatch(searchReset());
		}
		setSearchOffset(0);
		setSearchValue(e.target.value);
	};

	/** 리스트 클릭 이벤트 */
	const onClickStoreName = (item) => {
		map.current.setCenter(new naver.maps.LatLng(item.y, item.x));
		onClickStore(item);
		resetMode();
	};

	const resetMode = () => {
		setSearchMode(false);
		setDetailMode(false);
	};

	const clearDetailMode = () => {
		setDetailMode(false);
	};

	const onClickInfoDetailTab = useCallback(
		(handle) => () => {
			infoDetailEl.current.scrollTop = 0;
			setInfoDetailActive(handle);
		},
		[]
	);

	const onChangeReview = (reviewDate) => () => {
		dispatch(
			postStoreInfo({
				account: accounts[0]['username'],
				id: activeStore?.data?.id,
				date: reviewDate,
			})
		);
	};

	useEffect(() => {
		if(userInfoData?.kor_nm == null || userInfoData === undefined) {
			dispatch(userInfo({ account: accounts[0]['username'], kor_nm: accounts[0]['name'] }));
			console.log("userInfoData >> ", userInfoData);
		}
	}, [accounts, dispatch, userInfoData]);

	useEffect(() => {
		if(status == null || status === undefined){
			dispatch(
				postStoreInfo({
					account: accounts[0]['username'],
					id: activeStore?.data?.id,
					date: reviewDate,
				})
			);
		}else{
			if(checkStoreStatusInfo(activeStore?.["sub_category_code"], userInfoData?.team_mng_yn, activeStore?.["oper_region_cd"], userInfoData?.rfc_yn, activeStore?.["rfc_region_cd"], userInfoData?.rfc_adm_yn, userInfoData?.region_cd)){
				setActive("status");
			}else{
				setActive("competitor");
			}
		}
	}, [activeStore, dispatch, reviewDate, status, accounts[0]['username']]);

	// 네이버 리뷰 대상 카테고리
	const review_category = ["C1001", "C2001"];
	// 경쟁점 현황 대상 카테고리
	const competition_category = ["C1002", "C1003", "C1004"];

	/*
		네이버 리뷰 조회 권한
	*/
	const checkAuthNaverReview = (sub_category_code) => {
		if(review_category.includes(sub_category_code)){
			return true;
		}else{
			return false;
		}
	}

	/* 영업팀 정보 조회 권한
		- 자사 편의점: 개발팀 제외하고 전체	 (sub_category_code === "C1001" && rfc_yn !== "Y")
		- 자사 수퍼: 전체					(sub_category_code === "C2001")
	*/
	const checkAuthOperTeamInfo = (sub_category_code, rfc_yn) => {
		if(sub_category_code === "C1001" && rfc_yn !== "Y"){
			return true;
		}else if(sub_category_code === "C2001"){
			return true;
		}else{
			return false;
		}
	}

	/* 개발팀 정보 조회 권한
		- 자사 편의점: 개발팀만
		- 타사 편의점: 영업팀 & 개발팀
	*/
	const checkAuthRFCTeamInfo = (sub_category_code, team_mng_yn, part_mng_yn, rfc_yn) => {
		if(sub_category_code === "C1001" && rfc_yn === "Y"){
			return true;
		}else if(competition_category.includes(sub_category_code) && (rfc_yn === "Y" || team_mng_yn === "Y" || part_mng_yn === "Y")){
			return true;
		}else{
			return false;
		}
	}

	/* OFC/점장 정보 조회 권한
		- 자사 편의점/수퍼 전체
	*/
	const checkManagerInfo = (sub_category_code) => {
		if(review_category.includes(sub_category_code)){
			return true;
		}else{
			return false;
		}
	}

	/* 예상매출액 정보 조회 권한
		- 편의점 영업팀장: 소속지역팀 타사 편의점
		- 개발팀: 소속지역팀 편의점 전체
		- 개발기획: 편의점 전체
	*/
	const checkExpAmountInfo = (sub_category_code, team_mng_yn, oper_region_cd, rfc_yn, rfc_region_cd, rfc_adm_yn, region_cd) => {
		if(competition_category.includes(sub_category_code) && team_mng_yn === "Y" && oper_region_cd === region_cd){
			return true;
		}else if(competition_category.includes(sub_category_code) && rfc_yn === "Y" && rfc_region_cd === region_cd){
			return true;
		}else if(competition_category.includes(sub_category_code) && rfc_adm_yn === "Y"){
			return true;
		}else{
			return false;
		}
	}

	const checkStoreStatusInfo = (sub_category_code, team_mng_yn, oper_region_cd, rfc_yn, rfc_region_cd, rfc_adm_yn, region_cd) => {
		if((sub_category_code === "C1001" || competition_category.includes(sub_category_code)) && team_mng_yn === "Y" && oper_region_cd === region_cd){
			return true;
		}else if((sub_category_code === "C1001" || competition_category.includes(sub_category_code)) && rfc_yn === "Y" && rfc_region_cd === region_cd){
			return true;
		}else if((sub_category_code === "C1001" || competition_category.includes(sub_category_code)) && rfc_adm_yn === "Y"){
			return true;
		}else{
			return false;
		}
	}
	
	return (
		<HomeWrap isTabletOrMobile={isTabletOrMobile} height={height}>
			<div className='left-container'>
				<TabletOrMoblieSidebar
					codeFilter={codeFilter}
					onClickFilterButton={onClickFilterButton}
					distance={distance}
					onClickDistanceButton={onClickDistanceButton}
					setSearchMode={resetMode}
					onSearch={() => {
						setSearchMode(true);
					}}
					detailMode={detailMode}
					clearDetailMode={clearDetailMode}
				/>
			</div>
			<div
				id='map'
				style={{ width: '100%', height: `calc(${height} - 60px)}` }}
			/>
			{activeStore?.open && (
				<div className='active-store-info'>
					<div onClick={() => setDetailMode(true)}>
						<div className='active-store-name'>
							<h2>{activeStore['data'].name}</h2>
						</div>
						<div className='active-store-details'>
							<p>{activeStore['data'].category}</p>
							<p>{activeStore['data'].phone}</p>
						</div>
						<p className='active-store-address'>
							{activeStore['data'].full_address}
						</p>
					</div>
					<button
						className='active-store-info-close'
						onClick={(e) => {
							e.preventDefault();
							setActiveStore({
								open: false,
								data: null,
							});
							deleteCircle();
							dispatch(storeDetailReset());
							if (activeStore?.data) {
								const marker = document.getElementById(
									`${activeStore.data.name}`
								);
								marker && marker.parentElement.classList.remove('active');
							}
						}}
					>
						<Close />
					</button>
				</div>
			)}

			{searchMode && (
				<SearchWrap>
					<div className='search-header'>
						<button
							className='search-header-back'
							onClick={() => setSearchMode((prev) => !prev)}
						>
							<NavigateBeforeIcon fontSize='large' sx={{paddingRight: 2}}/>
						</button>
						<div className='search-input-wrap'>
							<input
								placeholder='상호명을 검색해 주세요'
								value={searchValue}
								onChange={onChangeSearchValue}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										onSearch()();
									}
								}}
							/>
							<button onClick={onSearch()}>
								<Search />
							</button>
						</div>
					</div>

					<ul id='search-list' className='section' ref={listEl}>
						{(searchStoreList ? searchStoreList : storeList)?.map(
							(item, index) => {
								if (
									(item['category'] === '편의점' &&
										codeFilter?.['C1000'] &&
										codeFilter['C1000'].includes(item['sub_category_code'])) ||
									(item['category'] === '슈퍼,마트' &&
										codeFilter?.['C2000'] &&
										codeFilter['C2000'].includes(item['sub_category_code'])) ||
									(item['category'] === '반찬가게' && codeFilter?.['C3000']) ||
									(item['category'] === '정육점' && codeFilter?.['C5000']) ||
									(item['category'] === '주유소' &&
										codeFilter?.['C4000'] &&
										codeFilter['C4000'].includes(item['sub_category_code']))
								) {
									return (
										<li
											key={'search-item' + item.id + index}
											className='search-item'
											onClick={() => {
												onClickStoreName(item);
											}}
										>
											<div className='search-item-title'>
												<h2>{item?.name}</h2>
												{item?.dst && item.dst !== 0 ? (
													<span className='dst'>
														{item.dst.toLocaleString()} m
													</span>
												) : item?.dst && item.dst === 0 ? (
													<span className='dst'>
														<RiPushpin2Fill />
													</span>
												) : null}
											</div>

											<div className='search-item-sub'>
												<p className='category'>{item?.category}</p>
												{item?.phone && item?.phone !== 'null' && (
													<em className='phone'>{item?.phone}</em>
												)}
											</div>
											<p className='address'>{item?.full_address}</p>
										</li>
									);
								}

								return null;
							}
						)}
					</ul>
				</SearchWrap>
			)}

			{detailMode && (
				<DetailWrap ref={infoEl} height={height}>
					<div className='active-store-info'>
						<div onClick={() => setDetailMode(true)}>
							<div className='active-store-name'>
								<h2>{activeStore['data']?.name}</h2>
							</div>
							<div className='active-store-details'>
								<p>{activeStore['data']?.category}</p>
								<p>{activeStore['data']?.phone}</p>
							</div>
							<p className='active-store-address'>
								{activeStore['data']?.full_address}
							</p>
						</div>
						<ul className="info-default-manager">
							{checkAuthOperTeamInfo(activeStore?.['data']?.sub_category_code, userInfoData?.rfc_yn) && (
							<li>
								<span>영업팀</span>
								<Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>{storeInfo?.["div_name"]}</Typography>
							</li>
							)}
							{checkAuthRFCTeamInfo(activeStore?.['data']?.sub_category_code, userInfoData?.team_mng_yn, userInfoData?.part_mng_yn, userInfoData?.rfc_yn) && (
							<li>
								<span>개발팀</span>
								<Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>{areaInfo == null ? '-': areaInfo["rfc_region_nm"]}</Typography>
							</li>
							)}
							{checkManagerInfo(activeStore?.['data']?.sub_category_code) && (
							<li>
								<span>OFC/점장</span>
								<Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>{storeInfo?.["kornm"]}</Typography>
							</li>
							)}
							{checkExpAmountInfo(activeStore?.['data']?.sub_category_code, userInfoData?.team_mng_yn, activeStore?.['data']?.oper_region_cd, userInfoData?.rfc_yn, activeStore?.['data']?.rfc_region_cd, userInfoData?.rfc_adm_yn, userInfoData?.region_cd) && (
							<li>
								<span>예상 일매출</span>
								{/* <Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>{areaInfo == null ? '-': formatToIntegerWithCommas(areaInfo["exp_sales_amount"])}</Typography> */}
								<Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>
									{areaInfo == null ? '-'
										: (
												areaInfo["signal"] === '-' 
													? formatToIntegerWithCommas(areaInfo["exp_sales_amount"])
													: (
															areaInfo["signal"] ==='UP' 
															? formatToIntegerWithCommas(areaInfo["exp_sales_amount"]) + ' (▲' + formatToIntegerWithCommas(areaInfo["exp_sales_amount_pre"]) + ')'
															:	(areaInfo["signal"] ==='DOWN'
																	? formatToIntegerWithCommas(areaInfo["exp_sales_amount"]) + ' (▼' + formatToIntegerWithCommas(areaInfo["exp_sales_amount_pre"]) + ')'
																	: formatToIntegerWithCommas(areaInfo["exp_sales_amount"])
																)
														)
											)}
								</Typography>
							</li>
							)}
						</ul>
						<ul className='info-detail-header'>
							<li>
							<button className={infoDetailActive === "competitor" ? "active" : ""} onClick={onClickInfoDetailTab("competitor")}>주변 점포</button>
							</li>
							{checkAuthNaverReview(activeStore?.['data']?.sub_category_code) && (
							<li>
								<button className={infoDetailActive === "review" ? "active" : ""} onClick={onClickInfoDetailTab("review")}>네이버 리뷰</button>
							</li>
							)}
							{checkStoreStatusInfo(activeStore?.['data']?.sub_category_code, userInfoData?.team_mng_yn, activeStore?.['data']?.oper_region_cd, userInfoData?.rfc_yn, activeStore?.['data']?.rfc_region_cd, userInfoData?.rfc_adm_yn, userInfoData?.region_cd) && (
							<li>
								<button className={infoDetailActive === "status" ? "active" : ""} onClick={onClickInfoDetailTab("status")}>점포 현황</button>
							</li>
							)}
						</ul>

						<div className='info-detail' ref={infoDetailEl}>
							<div className='info-detail-body'>
							<Typography sx={{ fontSize: 13, mb:"15px", color: "grey" }}>※주거/인구/교통/학군/상권 정보는 부동산114 제공 정보입니다.</Typography>
							{infoDetailActive === "status" ? (
								<>
								{areaInfoDetail?.length > 0 ? (
								  areaInfoDetail.map((cat, index) => (
									<div key={index}>
									  <Typography key={index} sx={{ fontSize: 15, fontWeight: "bold" }}>{cat["category_name"]}</Typography>
									  <CardContent
									  sx={{
									  bgcolor: "#F0F0F0",
									  borderRadius: "10px",
									  marginBottom: "20px",
									  }}
									  >
										{cat["info_detail"].map((info, index) => (
										  <Grid item xs={12} sm container key={index}>
												<Grid item xs={7}>
													<Typography variant="body2">{info["code_name"] }</Typography>
												</Grid>
												{areaInfo["signal"] !== '-' && (
													<Grid item xs={2.5} sx={{ textAlign: "right" }}>
														<Typography variant="body2" sx={{ fontWeight: "bold" }}>
															{	info["signal"] === 'UP' 
																? '(▲' + formatToIntegerWithCommas(info["code_value_pre"]) + ')'
																:	(info["signal"] ==='DOWN'
																		?  '(▼' + formatToIntegerWithCommas(info["code_value_pre"]) + ')'
																		: info["signal"] === "DIFF" 
																				? info["code_value_pre"] + " → " 
																				: ""
																	)
															}
														</Typography>
													</Grid>)}
												<Grid item xs={(areaInfo["signal"] === '-' ? 5 : 2.5)} sx={{ textAlign: "right" }}>
													<Typography variant="body2" sx={{ fontWeight: "bold" }}>
														{info["code_value"] == null ? '-': (info["code_type"] === "INT" ? formatToIntegerWithCommas(info["code_value"]): info["code_value"])}
													</Typography>
												</Grid>
											</Grid>
										))}
									  </CardContent>
									</div>
								  ))) : (
									<CardContent
									  sx={{
									  bgcolor: "#F0F0F0",
									  borderRadius: "10px",
									  marginBottom: "20px",
									  textAlign: 'center',
									  }}
									  >
										<Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>점포현황 등록 내역이 없습니다</Typography>
									  </CardContent>
								  )}
							  </>
							) : infoDetailActive === 'competitor' ? (
									<>
										{fixedStoreFilterList &&
											Object.entries(fixedStoreFilterList).map(
												([key, value]) => (
													<BrandDistanceList
														key={key}
														brand={key}
														value={value}
														// handle={true}
													/>
												)
											)}
									</>
								) : infoDetailActive === 'review' ? (
									<>
										<div className='info-detail-keyword'>
											<div className='info-detail-keyword-header'>
												<h2>
													키워드 리뷰{' '}
													<em>
														{storekeywordStat?.[0]?.['totCnt']
															? storekeywordStat[0]['totCnt']
															: '0'}
													</em>
												</h2>
												<Select value={reviewDate}>
													<li
														className={reviewDate === '1 개월' ? 'active' : ''}
														onClick={onChangeReview('1 개월')}
													>
														1 개월
													</li>
													<li
														className={reviewDate === '3 개월' ? 'active' : ''}
														onClick={onChangeReview('3 개월')}
													>
														3 개월
													</li>
													<li
														className={reviewDate === '6 개월' ? 'active' : ''}
														onClick={onChangeReview('6 개월')}
													>
														6 개월
													</li>
												</Select>
											</div>
											{storekeywordStat?.length > 0 && (
												<ul className='info-detail-keyword-list'>
													{storekeywordStat.map((keyword, i) => (
														<InfoKeywordBarWrap
															key={'keyword-list' + i}
															per={keyword.per}
														>
															<p>{keyword['display_name']}</p>
															<span>{keyword['cnt']}</span>
														</InfoKeywordBarWrap>
													))}
												</ul>
											)}
										</div>
										<div className='info-detail-review'>
											<ul>
												{storeReviewList?.length > 0 ? (
													storeReviewList.map((review, index) => (
														<li key={review['review_id'] + index}>
															<p className='reviewer'>
																{review['author_nickname']}
															</p>
															<p className='body'>{review['body']}</p>
															<VotedkeywordsWrap>
																{review['voted_keywords']?.map((vote, i) => (
																	<li key={review['review_id'] + index + i}>
																		{vote}
																	</li>
																))}
															</VotedkeywordsWrap>
															<p className='rest'>
																방문일 :{' '}
																{moment(review['author_visited']).format(
																	'MMM Do'
																)}{' '}
																· 작성일 :{' '}
																{moment(review['author_created']).format(
																	'MMM Do'
																)}
															</p>
														</li>
													))
												) : (
													<li className='info-detail-review-none'>
														<div>
															<div className='info-detail-review-none-icon'>
																<AiOutlineExclamation />
															</div>
															<p>
																최근 {reviewDate} 수집된 리뷰 데이터가 없습니다.
															</p>
														</div>
													</li>
												)}
											</ul>
										</div>
									</>
								) : null}
							</div>
						</div>
					</div>
				</DetailWrap>
			)}
			{storeMore.open && (
				<StoreMoreListWrap
					left={storeMore.position.left}
					bottom={storeMore.position.bottom - 60}
				>
					<ul>
						{storeMore.child.map((store, i) => (
							<li
								key={store.name + i}
								className='marker-button-child-item'
								onClick={() => {
									onClickStore(store);
								}}
							>
								{store.name}
							</li>
						))}
					</ul>
				</StoreMoreListWrap>
			)}
		</HomeWrap>
	);
};

export default MobileHomePage;

const DetailWrap = styled.div`
	box-sizing: border-box;
	z-index: 900;
	background-color: #fff;
	height: ${({ height }) =>
		height ? `calc(${height}px - 60px);` : 'calc(100vh - 60px);'};
	.active-store-info {
		box-sizing: border-box;
		position: relative !important;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 500;
		padding: 0 20px 0 !important;
		> div {
			box-shadow: none !important;
			padding: 10px 0 0 !important;
		}
	}

	.info-default-manager {
		display: flex;
		align-items: top;
		justify-content: space-between;
		margin: 20px -4.5px 20px;

		> li {
			flex: 1;
			border: 1px solid #d1d5db;
			box-sizing: border-box;
			border-radius: 8px;
			margin: 0 4.5px;
			text-align: center;
			padding: 4px 4px 6px;
			overflow: hidden;
		}
		> li span {
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
		}
		> li p {
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
		}
	}

	.info-detail {
		overflow-y: auto;
		height: calc(100vh - 318px);
		box-sizing: border-box;
	}

	.info-detail-header {
		display: flex;
		align-items: center;
		border-top: 1px solid #d1d5db;
		border-bottom: 1px solid #d1d5db;
		box-sizing: border-box;
		background: #fff;

		&.on {
			position: fixed;
			top: 53px;
			width: 360px;
			margin-left: -20px;
			z-index: 50;
			border-top-width: 0;
		}

		> li {
			flex: 1;
			text-align: center;
		}
		> li button {
			box-sizing: border-box;
			font-size: 16px;
			padding: 14px 0;
			&.active {
				border-bottom: 3px solid #1473e6;
				padding: 14px 0 11px;
				font-weight: 700;
			}
		}
	}

	.info-detail-keyword {
		padding-bottom: 22px;
		border-bottom: 1px solid #e5e7eb;
	}

	.info-detail-keyword-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> h2 {
			font-weight: bold;
			font-size: 18px;
			line-height: 45px;
			vertical-align: middle;
		}
		> h2 em {
			font-weight: 500;
			font-size: 18px;
			line-height: 45px;
			color: #1473e6;
			vertical-align: bottom;
		}

		.select-wrap {
			border-width: 0;
			padding: 0;
			> p {
				margin: 0 4px;
			}
			> ul {
				top: calc(100% - 4px);
			}
		}
	}

	.info-detail-review > ul li {
		border-bottom: 1px solid #e5e7eb;
		padding: 12px 0;
	}

	.info-detail-review .reviewer {
		font-size: 14px;
		line-height: 20px;
		font-weight: 700;
	}
	.info-detail-review .body {
		font-size: 12px;
		line-height: 20px;
		font-weight: 400;
	}

	.info-detail-review .rest {
		font-size: 12px;
		line-height: 20px;
		font-weight: 400;
		color: #727272;
	}

	.info-detail-review-none {
		padding: 50px 0 !important;
		.info-detail-review-none-icon {
			display: inline-block;
			border-radius: 100%;
			border: 2px solid #666;
			margin-bottom: 20px;
			> svg {
				font-size: 50px;
				color: #666;
			}
		}
		text-align: center;
	}
`;

const SearchWrap = styled.div`
	z-index: 900;
	background-color: #fff;
	.search-header {
		box-sizing: border-box;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		z-index: 900;
		box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%), 5px 0 15px 0 rgb(0 0 0 / 10%);
		padding: 0 18px;
		background-color: #fff;
		height: 60px;
	}

	.search-input-wrap {
		box-sizing: border-box;
		flex: 1;
		padding: 2px 2px 2px 22px;
		border: 1px solid #9e9e9e;
		box-sizing: border-box;
		border-radius: 1000px;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;

		input {
			width: 100%;
			font-size: 16px;
			line-height: 16px;
			font-weight: 500;
			border-width: 0;
			flex: 1;
			padding-right: 20px;
			/* margin-right: 20px; */
			&::placeholder {
				color: #9e9e9e;
			}
		}
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 38px;
			height: 38px;
			background-color: #0c4284;
			border-radius: 100%;
			flex-shrink: 0;

			> svg {
				width: 18px;
				height: 18px;
			}
			> svg path {
				stroke: #fff;
			}
		}
	}

	#search-list {
		flex: 1;
		overflow-y: auto;
		height: calc(100vh - 60px);

		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
			background: #ffffff;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 3.5px;
			background-color: #ced4da;

			&:hover {
				background-color: #adb5bd;
			}
		}
		&::-webkit-scrollbar-track {
			background: #ffffff;
		}
	}

	#search-list .search-item {
		cursor: pointer;
		padding: 20px 20px;
		border-bottom: 1px solid #e5e7eb;
	}

	#search-list .search-item-title {
		display: flex;
		align-items: flex-end;
		margin-bottom: 8px;
	}
	#search-list .search-item-title h2 {
		font-size: 18px;
		font-weight: bold;
		margin-right: 8px;
		line-height: 18px;
	}
	#search-list .search-item-title .dst {
		font-size: 14px;
		line-height: 14px;
		color: #3e85e8;
		white-space: nowrap;
	}
	#search-list .search-item-title .dst svg {
		width: 14px;
		height: 17px;
		vertical-align: bottom;
	}
	#search-list .search-item-sub .category {
		display: inline-block;
		color: #777777;
	}
	#search-list .search-item-sub .phone {
		color: #777777;
		&:before {
			display: inline-block;
			width: 1px;
			height: 8px;
			background-color: #e2e2e2;
			margin: -1px 6px 1px;
			vertical-align: -1px;
			content: '';
		}
	}
	#search-list .search-item-sub .address {
		color: #969696;
	}

	.search-header-back {
		display: flex;
		font-size: 34px;
	}
`;

export const HomeWrap = styled.div`
	position: fixed;
	display: flex;
	height: ${({ height }) => (height ? `${height}px !important;` : '100%;')};
	right: 0;
	top: 0;
	left: 0;
	flex-direction: ${({ isTabletOrMobile }) =>
		!isTabletOrMobile ? 'row;' : 'column;'};
	/* height: 100%; */

	overflow-y: auto;

	.store-detail,
	.caltex-store-detail {
		position: relative;
		border-left: 1px solid #d9d9d9;
		box-sizing: border-box;
		width: 360px;
		height: 100%;
	}

	.caltex-store-detail {
		> div:first-child {
			height: 100%;
			overflow-y: auto;
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			&::-webkit-scrollbar {
				display: none; /* Chrome, Safari, Opera*/
			}
		}
	}

	.close-wrap {
		position: absolute;
		z-index: 15;
		top: 30px;
		left: 100%;
		box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%), 5px 0 15px 0 rgb(0 0 0 / 10%);
		border-radius: 0 5px 5px 0;
		overflow: hidden;
		button {
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #e0e0e0;
			border-left-width: 0;
		}
		svg {
			width: 24px;
			height: 24px;
		}
	}

	.left-container {
		box-sizing: border-box;
		position: relative;
		display: flex;
		z-index: 10;
		box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%), 5px 0 15px 0 rgb(0 0 0 / 10%);
	}

	.store-detail-default {
		/* margin-bottom: 30px; */
		padding: 30px 20px;
		border-bottom: 6px solid #f0f2f5;
	}
	.store-detail-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;
	}

	.store-detail-title h1 {
		font-size: 24px;
	}
	.store-detail-title button svg {
		width: 22px;
		height: 22px;

		path {
			color: #d9d9d9;
		}
	}

	.store-detail-title button.on svg path {
		color: #ffeb5a;
	}

	.store-admin {
		/* padding: 30px 0; */
		border-bottom: 6px solid #f0f2f5;
		> div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 16px;
			border-bottom: 1px solid #e0e0e0;
		}
	}

	.custom-infow-window {
		background-color: #fff;
		color: #333;
		padding: 5px;
		border: #e0e0e0;
		border-radius: 5px;
		box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%), 5px 0 15px 0 rgb(0 0 0 / 10%);
	}

	.label-textarea {
		display: block;
		font-size: 14px;
		color: #757575;
		margin-bottom: 8px;
	}

	#map {
		flex: 1;
	}

	#map .marker-wrap {
		padding-top: 8px;
		&.active .marker-button,
		&.hover .marker-button {
			padding: 4px 8px 4px 4px;
			z-index: 20;
		}
		&.active .marker-button > .marker-none-img,
		&.hover .marker-button > .marker-none-img {
			margin-right: 6px;
		}
	}

	#map .marker-button {
		position: relative;
		display: flex;
		padding: 4px;
		border-radius: 23px;
		background: #fff;
		align-items: center;
		white-space: nowrap;

		& > .marker-none-img {
			width: 30px;
			height: 30px;
			border-radius: 100%;
			background-color: #ef7300;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		& > img {
			width: 30px;
		}
	}

	#map .marker-button.store {
		border: 1px solid #3e85e8;
		& .marker-none-img {
			background-color: #3e85e8;
		}
		.marker-button-count {
			border: 1px solid #3e85e8;
			color: #3e85e8;
		}
	}

	#map .marker-button.store.rfc {
		border: 2px solid rgba(242,101,34, 0.7);
		& .marker-none-img {
			background-color: rgba(242,101,34, 0.7);
		}
		.marker-button-count {
			border: 1px solid rgba(242,101,34, 1);
			color: rgba(242,101,34, 1);
		}
	}

	#map .marker-button.gas-station {
		border: 1px solid #ff8099;
		z-index: 10;
		&:after {
			box-shadow: -1px 1px 1px 0 #ff8099;
		}
		& .marker-none-img {
			background-color: #ff8099;
		}
		.marker-button-count {
			border: 1px solid #ff8099;
			color: #ff8099;
		}
	}

	#map .marker-button.mart-station {
		border: 1px solid #62d655;
		&:after {
			box-shadow: -1px 1px 1px 0 #62d655;
		}
		& .marker-none-img {
			background-color: #62d655;
		}
		.marker-button-count {
			border: 1px solid #62d655;
			color: #62d655;
		}
	}

	#map .marker-button.side-dish-station {
		border: 1px solid #9868ff;
		&:after {
			box-shadow: -1px 1px 1px 0 #9868ff;
		}
		& .marker-none-img {
			background-color: #9868ff;
		}
		.marker-button-count {
			border: 1px solid #9868ff;
			color: #9868ff;
		}
	}

	#map .marker-button.meat {
		border: 1px solid #f98e40;
		&:after {
			box-shadow: -1px 1px 1px 0 #f98e40;
		}
		& .marker-none-img {
			background-color: #f98e40;
		}
		.marker-button-count {
			border: 1px solid #f98e40;
			color: #f98e40;
		}
	}

	#map .marker-button:after {
		background: linear-gradient(
			45deg,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 0) 51%,
			rgba(255, 255, 255, 0) 100%
		);
		box-shadow: -1px 1px 1px 0 #0475f4;
		content: '';
		height: 9px;
		left: 19px;
		position: absolute;
		top: 37px;
		transform: translate(-50%, -50%) rotate(-45deg);
		width: 9px;
	}

	#map .marker-button.store.rfc:after {
		background: linear-gradient(
			45deg,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 0) 51%,
			rgba(255, 255, 255, 0) 100%
		);
		box-shadow: -2px 2px 1px rgba(242,101,34, 0.7);
		content: '';
		height: 9px;
		left: 19px;
		position: absolute;
		top: 37px;
		transform: translate(-50%, -50%) rotate(-45deg);
		width: 9px;
	}

	#map .marker-wrap.active img,
	#map .marker-wrap.hover img {
		margin-right: 6px;
	}
	#map .marker-button .marker-button-name {
		/* width: 0;
		overflow: hidden; */
		display: none;
		color: #333;
		opacity: 0;
		font-family: 'Noto Sans KR', sans-serif !important;
	}
	#map .marker-wrap.active .marker-button .marker-button-name,
	#map .marker-wrap.hover .marker-button .marker-button-name {
		/* transition: all 400ms; */
		/* width: 100%; */
		display: inline-block;
		opacity: 1;
	}

	#map .marker-button-count {
		width: 20px;
		height: 15px;
		position: absolute;
		top: -6px;
		right: -4px;
		border-radius: 9px;
		background-color: #fff;
		vertical-align: middle;
		text-align: center;
		font-size: 11px;
		line-height: 15px;
		font-weight: bold;
	}

	#map .marker-wrap.active .marker-button-child {
		display: block;
	}

	#map .marker-button-child {
		display: none;
		position: absolute;
		bottom: 100%;
		left: 0;
		margin-bottom: 8px;
		z-index: 1000;
		> ul {
			max-height: 270px;
			overflow-y: auto;
			background-color: #fff;
			border: 1px solid rgba(0, 0, 0, 0.05);
			box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
			&::-webkit-scrollbar {
				width: 6px;
				height: 6px;
				background: #ffffff;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 3.5px;
				background-color: #ced4da;

				&:hover {
					background-color: #adb5bd;
				}
			}
			&::-webkit-scrollbar-track {
				background: #ffffff;
			}

			> li {
				padding: 10px 28px;
				white-space: nowrap;
				border-bottom: 1px solid #ecf0f2;
				text-align: left;
				color: #333;
				font-family: 'Noto Sans KR', sans-serif !important;
				&:last-child {
					border-width: 0;
				}
			}
		}
	}

	.active-store-info {
		box-sizing: border-box;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 0 20px 25px;
		z-index: 500;
		> div {
			box-sizing: border-box;
			width: 100%;
			padding: 16px 14px 18px;
			box-shadow: rgb(0 0 0 / 15%) 3px 3px 15px;
			border-radius: 10px;
			background-color: #fff;

			.active-store-name {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 8px;
			}

			.active-store-name h2 {
				font-size: 18px;
				font-weight: bold;
				color: #333;
				flex: 1;
				margin-right: 14px;
			}

			.active-store-details {
				display: flex;
				align-items: center;
			}

			.active-store-details p {
				color: #777;

				&:nth-child(2)::before {
					display: inline-block;
					width: 1px;
					height: 8px;
					background-color: rgb(226, 226, 226);
					margin: -1px 6px 1px;
					vertical-align: -1px;
					content: '';
				}
			}

			p.active-store-address {
				color: #333;
			}
		}
	}

	.active-store-info-close {
		position: absolute;
		top: 13px;
		right: 35px;
		padding: 6px;

		> svg {
			width: 18px;
			height: 18px;
		}
	}
`;

const VotedkeywordsWrap = styled.ul`
	li {
		padding: 5px 20px;
		background: #ffffff;
		border: 0.5px solid #e2e5e9;
		box-sizing: border-box;
		box-shadow: 0px 2px 5px rgba(31, 41, 55, 0.15);
		border-radius: 8px;
		color: #374151;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
	}
`;

const InfoKeywordBarWrap = styled.li`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
	border: 1px solid #d1d5db;
	border-radius: 8px;
	margin-bottom: 5px;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: ${({ per }) => (per ? `${per}%;` : '0;')};
		height: 41px;
		background-color: #dae9fc;
		z-index: 10px;
	}

	&:last-child {
		margin-bottom: 0;
	}
	> p {
		font-weight: 500;
		font-size: 14px;
		z-index: 30;
	}
	> span {
		color: #1473e6;
		font-weight: 600;
		font-size: 14px;
		z-index: 30;
	}
`;
