import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
	postStoreDistanceApi,
	postStoreSearchApi,
	postCaltexListApi,
	postStoreInfoApi,
} from '../api/store';
import { CodeFilterType } from '../data/code';
import aixos, { AxiosError } from 'axios';
// import _ from 'lodash';

export const postStoreDistance = createAsyncThunk(
	'postStoreDistance',
	async (
		{
			lat,
			lng,
			distance,
			account,
		}: { lat: string; lng: string; distance: string; account: string },
		{ dispatch, getState, rejectWithValue }
	) => {
		try {
			const result = await postStoreDistanceApi({
				lat,
				lng,
				distance,
				account,
			});

			return { recordset: result.data.data };
		} catch (err) {
			const errors = err as Error | AxiosError;
			if (!aixos.isAxiosError(errors)) {
				return;
				// rejectWithValue(err?.response?.data);
			}
		}
	}
);

export const postCaltexList = createAsyncThunk(
	'postCaltexList',
	async (
		{ lat, lng, account }: { lat: string; lng: string; account: string },
		{ dispatch, getState, rejectWithValue }
	) => {
		try {
			const result = await postCaltexListApi({ lat, lng, account });
			return { recordset: result.data.data };
		} catch (err) {
			const errors = err as Error | AxiosError;
			if (!aixos.isAxiosError(errors)) {
				return;
				// rejectWithValue(err?.response?.data);
			}
		}
	}
);

export const postFixedDistance = createAsyncThunk(
	'postFixedDistance',
	async (
		{
			lat,
			lng,
			distance,
			account,
			codeFilter,
		}: {
			lat: string;
			lng: string;
			distance: string;
			account: string;
			codeFilter: CodeFilterType;
		},
		{ rejectWithValue }
	) => {
		try {
			const result = await postStoreDistanceApi({
				lat,
				lng,
				distance,
				account,
			});

			let subCategory: any = {};

			// eslint-disable-next-line array-callback-return
			Object.entries(codeFilter).map(([key, value]) => {
				if (value && value?.length > 0) {
					value.forEach((v) => {
						subCategory[v] = [];
					});
				}
			});

			result.data.data.forEach((store: StoreDataType) => {
				if (store.dst !== 0)
					subCategory?.[store['sub_category_code']]?.push(store);
			});

			return { result: result.data.data, filter: subCategory };
		} catch (err) {
			const errors = err as Error | AxiosError;
			if (!aixos.isAxiosError(errors)) {
				return;
				// rejectWithValue(err?.response?.data);
			}
		}
	}
);

export const postStoreSearch = createAsyncThunk(
	'postStoreSearch',
	async ({
		storeName,
		account,
		openSnackbar,
		offset,
		category,
	}: {
		storeName: string;
		account: string;
		openSnackbar: (t: boolean) => void;
		offset: number;
		category: '편의점' | '슈퍼,마트' | '반찬가게' | '정육점' | '주유소';
	}) => {
		try {
			if (!storeName.trim()) {
				return { searchStoreList: null };
			}

			const result = await postStoreSearchApi({
				storeName,
				account,
				offset,
				category,
			});

			if (result.data?.data) {
				return { searchStoreList: result.data.data, offset };
			} else {
				if (offset === 0) {
					openSnackbar(result.data.resMessage);
				}
				return { searchStoreList: null, offset };
			}
		} catch (err) {
			const errors = err as Error | AxiosError;
			if (!aixos.isAxiosError(errors)) {
				return;
				// rejectWithValue(err?.response?.data);
			}
		}
	}
);

export const postStoreInfo = createAsyncThunk(
	'postStoreInfo',
	async (
		{ id, account, date }: { id: string; account: string; date: string },
		{ dispatch, getState, rejectWithValue }
	) => {
		try {
			const { store } = getState() as { store: StoreStateType };
			const prevReviewDate = store.reviewDate as ReviewDateType;
			const getReviewDate = date ? date : prevReviewDate;

			const result = await postStoreInfoApi({
				id,
				account,
				date: getReviewDate,
			});

			return { storeInfo: result.data, getReviewDate };
		} catch (err) {
			const errors = err as Error | AxiosError;
			if (!aixos.isAxiosError(errors)) {
				return;
				// rejectWithValue(err?.response?.data);
			}
		}
	}
);

// reviewDate type
type ReviewDateType = '1 개월' | '3 개월' | '6 개월';

// store state type
type StoreDataType = {
	dst: number;
	phone: string;
	category_code: string;
	sub_category: string;
	name: string;
	x: string;
	y: string;
	id: string;
	full_address: string;
	category: string;
	sub_category_code: string;
	rfc_region_cd: string;
	oper_region_cd: string;
};

type StoreInfo = {
	div_code: string;
	negative: number;
	div_name: string;
	mail: string;
	p_div_code: string;
	kornm: string;
	phone: string;
	name: string;
	neutral: number;
	positive: number;
	p_div_name: string;
	id: string;
};

type StoreReview = {
	author_visited: string;
	review_id: string;
	neg: number;
	mail: string;
	p_div_code: string;
	kornm: string;
	phone: string;
	name: string;
	neutral: number;
	positive: number;
	p_div_name: string;
	id: string;
};

type StorekeywordStat = {
	code: string;
	totCnt: number;
	cnt: number;
	id: string;
	display_name: string;
	per: number;
};
type areaInfo = {
	store_id: string;
	place_id: string;
	rfc_region_cd: string;
	rfc_region_nm: string;
	oper_region_cd: string;
	exp_sales_amount: number;
};
type areaInfoDetail = {
	store_id: string;
	category_id: string;
	category_seq: number;
	category_name: string;
	info_detail: codeValues[];
};
type codeValues = {
	code_name: string;
	code_type: string;
	code_seq: number;
	code_value: string;
}
type ActiveStoreData = {
	category: string;
	category_code: string;
	child: any[];
	coord: { y: number; _lat: number; x: number; _lng: number };
	dst: number;
	full_address: string;
	id: string;
	name: string;
	offset: { x: number; y: number };
	phone?: string;
	sub_category: string;
	sub_category_code: string;
	x: string;
	y: string;
	rfc_region_cd: string;
	oper_region_cd: string;
};

interface StoreStateType {
	status: string | null;
	reviewDate: ReviewDateType;
	storeList: StoreDataType[] | null;
	searchStoreList: StoreDataType[] | null;
	searchLoading: boolean;
	fixedStoreFilterList: StoreDataType[] | null;
	storeInfo: StoreInfo | null;
	storeReviewList: StoreReview[] | null;
	storekeywordStat: StorekeywordStat[] | null;
	areaInfo: areaInfo | null;
	areaInfoDetail: areaInfoDetail[] | null;
	activeStore: { open: boolean; data: ActiveStoreData } | null;
}

const initialState: StoreStateType = {
	status: null,
	searchLoading: false,
	storeList: null,
	searchStoreList: null,
	fixedStoreFilterList: null,
	storeInfo: null,
	storeReviewList: null,
	storekeywordStat: null,
	areaInfo: null,
	areaInfoDetail: null,
	reviewDate: '3 개월',
	activeStore: null,
};

export const storeSlice = createSlice({
	name: 'store',
	initialState,
	reducers: {
		searchReset: (state: StoreStateType, action: PayloadAction) => {
			state.searchStoreList = null;
		},
		storeDetailReset: (state: StoreStateType) => {
			state.storeInfo = null;
			state.fixedStoreFilterList = null;
		},
		areaInfoReset: (state: StoreStateType) => {
			state.areaInfo = null;
			state.areaInfoDetail = null;
		}
	},
	extraReducers: {
		[postStoreDistance.pending.type]: () => {},
		[postStoreDistance.fulfilled.type]: (state, { payload }) => {
			state.storeList = payload.recordset;
		},
		[postStoreDistance.rejected.type]: () => {},

		[postFixedDistance.pending.type]: () => {},
		[postFixedDistance.fulfilled.type]: (state, { payload }) => {
			state.storeList = payload.result;
			state.fixedStoreFilterList = payload.filter;
		},
		[postFixedDistance.rejected.type]: () => {},
		
		[postStoreSearch.pending.type]: (state) => {
			state.searchLoading = true;
		},
		[postStoreSearch.fulfilled.type]: (state, { payload }) => {
			state.searchLoading = false;

			if (payload?.offset >= 30) {
				state.searchStoreList = [
					...(state?.searchStoreList ? state?.searchStoreList : []),
					...(payload?.searchStoreList ? payload.searchStoreList : []),
				];
			} else {
				state.searchStoreList = payload.searchStoreList;
			}
		},
		[postStoreSearch.rejected.type]: (state) => {
			state.searchLoading = false;
		},
		
		[postStoreInfo.pending.type]: () => {},
		[postStoreInfo.fulfilled.type]: (state, { payload }) => {
			state.status = payload.storeInfo.resStatus;
			state.storeReviewList = payload.storeInfo.data.review;
			state.storekeywordStat = payload.storeInfo.data.keywordStat;
			state.storeInfo = payload.storeInfo.data.place;
			state.reviewDate = payload.getReviewDate;
			state.areaInfo = payload.storeInfo.data.areaInfo;
			state.areaInfoDetail = payload.storeInfo.data.areaInfoDetail;
		},
		[postStoreInfo.rejected.type]: () => {},
	},
});

export const { searchReset, storeDetailReset, areaInfoReset } = storeSlice.actions;

export default storeSlice.reducer;
