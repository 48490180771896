import { useState, useRef } from 'react';
import styled from 'styled-components';
import JoditEditor from 'jodit-react';
// cp
import Modal from '../../components/modal';
// api

const editorConfig = {
	readonly: false,
	language: 'ko',
	maxHeight: 350,
	minHeight: 350,
	autofocus: true,
	zIndex: 500,
	useSearch: true,
	statusbar: false,
	placeholder: '정보를 입력해주세요.',
	// toolbar:false;
	// buttons: ['bold', 'italic', 'source'],
	// i18n: {
	// 	ru: {
	// 		'Type something': 'Начните что-либо вводить',
	// 	},
	// },
};

const PostModal = ({ user, postModal, setPostModal, activeStore }) => {

	const editor = useRef(null);
	const [content, setContent] = useState('');

	const modalReset = () => {
		setPostModal(false);
		setContent('');
	};

	return (
		<ModalWrap
			open={postModal}
			headline={activeStore?.['name']}
		>
			<div className='modal-body'>
				<JoditEditor
					ref={editor}
					value={content}
					config={editorConfig}
					tabIndex={3} // tabIndex of textarea
					onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
					onChange={(newContent) => {}}
				/>
			</div>
			<div className='modal-footer'>
				<div className='modal-footer-center'>
					<button onClick={() => {}}>등록</button>
					<button onClick={modalReset}>취소</button>
				</div>
			</div>
		</ModalWrap>
	);
};

export default PostModal;

const ModalWrap = styled(Modal)`
	.modal-body {
		padding: 20px 24px 30px;
	}

	.modal-footer {
		button {
			padding: 8px 14px;
			border: 1px solid rgb(224, 224, 224);
			border-radius: 5px;
			&:first-child {
				margin-right: 10px;
			}
		}
	}
`;
