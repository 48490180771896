import { memo, useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { searchReset, postStoreSearch } from '../../reducers/store';
import Loader from '../../components/loader';

// icon
import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as Close } from '../../assets/close.svg';
import { RiPushpin2Fill } from 'react-icons/ri';

const { naver } = window;

let beforeScrollY = 0;

// interface SearchBarProps {}

const SearchBar = memo(
	({
		searchStoreList,
		defaultList,
		map,
		onClickStore,
		account,
		onChangeSideBar,
		searchCodeFilter,
		setSnackbarMessage,
	}) => {
		const listEl = useRef(null);
		const dispatch = useDispatch();
		const { searchLoading } = useSelector((state) => state.store);
		const [searchValue, setSearchValue] = useState('');
		const [searchOffset, setSearchOffset] = useState(0);

		const onSearch = useCallback((type) => () => {
			const category = [];
			Object.entries(searchCodeFilter).map(([key, value]) => {
				if (value?.length > 0) {
					category.push({
						code: key,
						sub_code: value,
					});
				}
				return null;
			});

			if (type === 'scroll') {
				setSearchOffset((prev) => prev + 30);
			} else {
				setSearchOffset(0);
				listEl.current.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
			}

			dispatch(
				postStoreSearch({
					storeName: searchValue,
					account: account,
					openSnackbar: setSnackbarMessage,
					category,
					offset: type === 'scroll' ? searchOffset + 30 : 0,
				})
			);
		}, [account, dispatch, searchCodeFilter, searchOffset, searchValue, setSnackbarMessage]);

		const eventScroll = useCallback((e) => {
			const SearchList = document.getElementById('search-list');

			if (
				beforeScrollY < SearchList.scrollTop &&
				searchStoreList &&
				!searchLoading &&
				SearchList.scrollTop + SearchList.offsetHeight >=
					SearchList.scrollHeight
			) {
				onSearch('scroll')();
			}
			beforeScrollY = SearchList.scrollTop;
		}, [onSearch, searchLoading, searchStoreList]);

		useEffect(() => {
			const SearchList = document.getElementById('search-list');
			SearchList.addEventListener('scroll', eventScroll);

			return () => {
				SearchList.removeEventListener('scroll', eventScroll);
			};
		}, [eventScroll]);

		

		useEffect(() => {
			return () => dispatch(searchReset());
		}, [dispatch]);

		useEffect(() => {
			if (!searchStoreList) {
				listEl.current.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
			}
		}, [searchStoreList, defaultList]);

		const onClickStoreName = (item) => {
			map.current.setCenter(new naver.maps.LatLng(item.y, item.x));
			onClickStore(item);
		};

		const onMouseLeaveList = useCallback(
			(name) => () => {
				const marker = document.getElementById(`${name}`);
				marker && marker.parentElement.classList.remove('hover');
			},
			[]
		);

		const onMouseEnterList = useCallback(
			(name) => () => {
				const marker = document.getElementById(`${name}`);
				marker && marker.parentElement.classList.add('hover');
			},
			[]
		);

		const onChangeSearchValue = (e) => {
			if (e.target.value.trim() === '') {
				dispatch(searchReset());
			}
			setSearchOffset(0);
			setSearchValue(e.target.value);
		};

		return (
			<SearchBarWrap>
				{searchLoading && <Loader />}
				<div className='search-header'>
					<button onClick={onChangeSideBar}>
						<Close />
					</button>
				</div>
				<div className='section search-input-section'>
					<div className='search-input-wrap'>
						<input
							placeholder='상호명을 검색해 주세요'
							value={searchValue}
							onChange={onChangeSearchValue}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									onSearch()();
								}
							}}
						/>
						<button onClick={onSearch()}>
							<Search />
						</button>
					</div>
				</div>
				<ul id='search-list' className='section' ref={listEl}>
					{(searchStoreList ? searchStoreList : defaultList)?.map(
						(item, index) => {
							if (
								(item['category'] === '편의점' &&
									searchCodeFilter?.['C1000'] &&
									searchCodeFilter['C1000'].includes(
										item['sub_category_code']
									)) ||
								(item['category'] === '슈퍼,마트' &&
									searchCodeFilter?.['C2000'] &&
									searchCodeFilter['C2000'].includes(
										item['sub_category_code']
									)) ||
								(item['category'] === '반찬가게' &&
									searchCodeFilter?.['C3000']) ||
								(item['category'] === '정육점' &&
									searchCodeFilter?.['C5000']) ||
								(item['category'] === '주유소' &&
									searchCodeFilter?.['C4000'] &&
									searchCodeFilter['C4000'].includes(item['sub_category_code']))
							) {
								return (
									<li
										key={'search-item' + item.id + index}
										className='search-item'
										onClick={() => {
											onClickStoreName(item);
										}}
										onMouseLeave={onMouseLeaveList(item.name)}
										onMouseEnter={onMouseEnterList(item.name)}
									>
										<div className='search-item-title'>
											<h2>{item?.name}</h2>
											{item?.dst && item.dst !== 0 ? (
												<span className='dst'>
													{item.dst.toLocaleString()} m
												</span>
											) : item?.dst && item.dst === 0 ? (
												<span className='dst'>
													<RiPushpin2Fill />
												</span>
											) : null}
										</div>

										<div className='search-item-sub'>
											<p className='category'>{item?.category}</p>
											<em className='phone'>{item?.phone}</em>
										</div>
										<p className='address'>{item?.full_address}</p>
									</li>
								);
							} else {
								return null;
							}
						}
					)}
				</ul>
			</SearchBarWrap>
		);
	}
);

export default SearchBar;

const SearchBarWrap = styled.div`
	box-sizing: border-box;
	/* width: 496px; */
	width: 360px;
	height: 100%;
	padding: 26px 20px 0;
	transition: all 350ms;
	display: flex;
	flex-direction: column;

	svg {
		vertical-align: middle;
	}
	.section {
		border-bottom: 1px solid #e5e7eb;
	}
	.search-header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 22px;
	}
	.search-input-section {
		overflow: hidden;
	}
	.search-input-wrap {
		box-sizing: border-box;
		width: 100%;
		padding: 8px 8px 8px 32px;
		border: 1px solid #9e9e9e;
		box-sizing: border-box;
		border-radius: 1000px;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;

		input {
			width: 100%;
			font-size: 16px;
			line-height: 16px;
			font-weight: 500;
			border-width: 0;
			flex: 1;
			padding-right: 20px;
			/* margin-right: 20px; */
			&::placeholder {
				color: #9e9e9e;
			}
		}
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			background-color: #0c4284;
			border-radius: 100%;
			flex-shrink: 0;

			> svg {
				width: 18px;
				height: 18px;
			}
			> svg path {
				stroke: #fff;
			}
		}
	}

	#search-list {
		flex: 1;
		overflow-y: auto;
		margin-right: -20px;
		padding-right: 20px;
		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
			background: #ffffff;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 3.5px;
			background-color: #ced4da;

			&:hover {
				background-color: #adb5bd;
			}
		}
		&::-webkit-scrollbar-track {
			background: #ffffff;
		}
	}

	.search-item {
		cursor: pointer;
		padding: 20px 20px;
		border-bottom: 1px solid #e5e7eb;
	}
	/* .search-item:hover {
		background: #f7f9fa;
	} */
	/* .search-item:last-child {
			border-width: 0;
		} */
	.search-item-title {
		display: flex;
		align-items: flex-end;
		margin-bottom: 8px;
	}
	.search-item-title h2 {
		font-size: 18px;
		font-weight: bold;
		margin-right: 8px;
		line-height: 18px;
	}
	.search-item-title .dst {
		font-size: 14px;
		line-height: 14px;
		color: #3e85e8;
		white-space: nowrap;
	}
	.search-item-title .dst svg {
		width: 14px;
		height: 17px;
		vertical-align: bottom;
	}
	.search-item-sub .category {
		display: inline-block;
		color: #777777;
	}
	.search-item-sub .phone {
		color: #777777;
		&:before {
			display: inline-block;
			width: 1px;
			height: 8px;
			background-color: #e2e2e2;
			margin: -1px 6px 1px;
			vertical-align: -1px;
			content: '';
		}
	}
	.search-item-sub .address {
		color: #969696;
	}
`;
