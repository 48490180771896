import { LicenseInfo } from '@mui/x-license-pro';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
// icon
import {
	FaChartBar,
} from 'react-icons/fa';
import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as Home } from '../../assets/home.svg';
import { userInfo } from '../../reducers/user';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { blue } from '@mui/material/colors';

LicenseInfo.setLicenseKey('09a113ecdba820f0fec7ea28633adbf3Tz04NjkzNCxFPTE3NDI2NDczNDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
// api
// import { getUserPanelApi } from '../../api/store';

interface PcSidebarProps {
	children?: React.ReactNode;
	map?: any;
	open?: boolean;
	setOpen?: () => void;
	onChangeSideBar?: () => void;
}

const PcSidebar = ({
	children,
	map,
	open,
	setOpen,
	onChangeSideBar,
}: PcSidebarProps) => {
	let location = useLocation();

	const dispatch = useDispatch();
	const userInfoData = useSelector((state: any) => state.user);
	const { accounts } = useMsal();

	useEffect(() => {
		if(userInfoData?.kor_nm == null) {
			dispatch(userInfo({ account: accounts[0]['username'], kor_nm: accounts[0]['name'] }));
		}
	}, [accounts, dispatch, userInfoData.kor_nm, userInfoData]);

	return (
		<PcSidebarWrap open={open}>
			<div className='main-bar'>
				<h1>
					<Link to='/'>
						<img alt='GS리테일' src='/sidebar-logo.png' />
					</Link>
				</h1>
				<nav>
					<ul>
						<li>
							<button
								className={location.pathname !== '/' ? 'sub-active' : ''}
								// onClick={() => {
								// 	setActive('search');
								// }}
								onClick={onChangeSideBar ? onChangeSideBar : () => {}}
							>
								<Search />
								점포 검색
							</button>
						</li>

						<li>
							<Link
								to='/'
								className={location.pathname === '/' ? 'active' : ''}
							>
								<Home />
								점포 지도
							</Link>
						</li>
						{userInfoData?.team_mng_yn === "Y" || userInfoData?.part_mng_yn === "Y"  || userInfoData?.sm_mng_yn === "Y" ? 
							<li>
								<Link to='/report' className={location.pathname === '/report' ? 'active' : ''}>
									<FaChartBar /> 담당점포 리뷰
								</Link>
							</li> 
						: null}
					</ul>
				</nav>
				<div className='side-other'>
					<button><AccountCircleIcon sx={{ color: blue[900] }}/></button>
					<Typography variant='body2' style={{ textAlign: 'center', fontWeight: 700, fontSize: "15px", fontFamily: "'Noto Sans KR', sans-serif"}}>
						{userInfoData?.team_nm == null ? accounts[0]['name']?.split('/')[1]: (userInfoData?.team_nm)}<br/>
						{userInfoData?.team_nm == null ? accounts[0]['name']?.split('/')[0]: (userInfoData?.kor_nm)}
					</Typography>
				</div>
			</div>
			{children && <div className='sub-bar'>{children}</div>}
		</PcSidebarWrap>
	);
};

export default PcSidebar;

interface PcSidebarWrapProps {
	open?: boolean;
}

const PcSidebarWrap = styled.div<PcSidebarWrapProps>`
	box-sizing: border-box;
	height: 100%;
	display: flex;

	.main-bar {
		display: flex;
		flex-direction: column;
		width: 210px;
		background-color: #fff;
		border-right: 1px solid #e5e7eb;

		h1 {
			align-items: center;
			display: flex;
			justify-content: center;
			padding: 64px 0 44px;
		}
		h1 img {
			width: 99px;
			cursor: pointer;
		}

		nav {
			flex: 1;
		}

		nav > ul li {
			padding-right: 20px;
		}

		nav > ul li a,
		nav > ul li button {
			box-sizing: border-box;
			display: flex;
			width: 100%;
			padding: 17px 0 18px 28px;

			justify-content: start;
			align-items: center;
			font-size: 18px;
			line-height: 25px;
			color: #0c4284;
			font-family: 'Noto Sans KR', sans-serif !important;
			font-weight: 500;
			
			&.active {
				background-color: #e2e5e9;
				border-radius: 0px 30px 30px 0px;
				color: #1473e6;

				svg path {
					stroke: #1473e6;
				}
			}
			&.sub-active {
				cursor: no-drop;
				/* color: #e2e5e9;
				svg path {
					stroke: #e2e5e9;
				} */
			}
		}

		nav > ul li a svg,
		nav > ul li button svg {
			width: 20px;
			height: 20px;
			margin-right: 14px;
		}

		.side-other {
			border-top: 1px solid #d9d9d9;
			height: 100px;
			padding-top: 18px;
			display: flex;
			flex-direction: column;
		}
		.side-other button:first-child {
			margin-bottom: 8px;
		}

		.side-other svg {
			width: 30px;
			height: 30px;
		}
	}

	.sub-bar {
		box-sizing: border-box;
		height: 100%;
		transition: all 350ms;

		${({ open }) =>
			!open &&
			`min-width: 0;
	
			> div {
				width: 0;
				opacity: 0;
				padding: 0;
			}
		`}
	}
`;
