import { memo, useState } from 'react';
import styled from 'styled-components';
// svg
import { ReactComponent as Cart } from '../../assets/cart.svg';
import { ReactComponent as Oil } from '../../assets/oil.svg';
import { ReactComponent as Food } from '../../assets/food.svg';
import { ReactComponent as Home } from '../../assets/home.svg';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { GiMeat } from 'react-icons/gi';

const FilterController = memo(({ codeFilter, onClickFilterButton }) => {
	// const [storeOpen, setStoreOpen] = useState(false);
	// const [martOpen, setMartOpen] = useState(false);
	// const [oilOpen, setOilOpen] = useState(false);
	const [open, setOpen] = useState(null);

	return (
		<FilterControllerWrap>
			<div className='main-category mart'>
				<div>
					<button
						className={`controller ${codeFilter?.['C2000'] && 'active'}`}
						onClick={onClickFilterButton({ mainCode: 'C2000' })}
					>
						<Cart />
					</button>
					<p>
						<em>마트</em>{codeFilter?.['C2000']?.length > 0 ? '(' + (codeFilter?.['C2000']?.length) + ')' : ''}
					</p>
					<button
						className={`category-btn-open ${open === 'mart' && 'on'}`}
						onClick={() =>
							setOpen((prev) => {
								if (!prev || prev !== 'mart') {
									return 'mart';
								} else {
									return null;
								}
							})
						}
					>
						{open === 'mart' ? <AiFillCaretUp /> : <AiFillCaretDown />}
					</button>
				</div>
				{open === 'mart' && (
					<ul className='sub-category'>
						<li
							className={
								codeFilter?.['C2000']?.includes('C2001') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C2000',
								subCode: 'C2001',
							})}
						>
							GS더프레시
						</li>
						<li
							className={
								codeFilter?.['C2000']?.includes('C2008') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C2000',
								subCode: 'C2008',
							})}
						>
							롯데슈퍼
						</li>
						<li
							className={
								codeFilter?.['C2000']?.includes('C2003') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C2000',
								subCode: 'C2003',
							})}
						>
							홈플러스익스프레스
						</li>
						<li
							className={
								codeFilter?.['C2000']?.includes('C2005') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C2000',
								subCode: 'C2005',
							})}
						>
							이마트에브리데이
						</li>
						<li
							className={
								codeFilter?.['C2000']?.includes('C2006') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C2000',
								subCode: 'C2006',
							})}
						>
							노브랜드
						</li>
						<li
							className={
								codeFilter?.['C2000']?.includes('C2011') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C2000',
								subCode: 'C2011',
							})}
						>
							하나로마트
						</li>
						<li
							className={
								codeFilter?.['C2000']?.includes('C2012') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C2000',
								subCode: 'C2012',
							})}
						>
							대형 마트
						</li>
						<li
							className={
								codeFilter?.['C2000']?.includes('C2999') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C2000',
								subCode: 'C2999',
							})}
						>
							기타 마트
						</li>
					</ul>
				)}
			</div>
			<div className='main-category store'>
				<div>
					<button
						className={`controller ${codeFilter?.['C1000'] && 'active'}`}
						onClick={onClickFilterButton({ mainCode: 'C1000' })}
					>
						<Home className='stroke' />
					</button>
					<p>
						<em>편의점</em>{codeFilter?.['C1000']?.length > 0 ? '(' + (codeFilter?.['C1000']?.length) + ')' : ''}
					</p>
					<button
						className={`category-btn-open ${open === 'store' && 'on'}`}
						onClick={() =>
							setOpen((prev) => {
								if (!prev || prev !== 'store') {
									return 'store';
								} else {
									return null;
								}
							})
						}
					>
						{open === 'store' ? <AiFillCaretUp /> : <AiFillCaretDown />}
					</button>
				</div>
				{open === 'store' && (
					<ul className='sub-category'>
						<li
							className={
								codeFilter?.['C1000']?.includes('C1001') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C1000',
								subCode: 'C1001',
							})}
						>
							GS25
						</li>
						<li
							className={
								codeFilter?.['C1000']?.includes('C1002') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C1000',
								subCode: 'C1002',
							})}
						>
							CU
						</li>
						<li
							className={
								codeFilter?.['C1000']?.includes('C1003') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C1000',
								subCode: 'C1003',
							})}
						>
							세븐일레븐
						</li>
						<li
							className={
								codeFilter?.['C1000']?.includes('C1004') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C1000',
								subCode: 'C1004',
							})}
						>
							이마트24
						</li>
						<li
							className={
								codeFilter?.['C1000']?.includes('C1999') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C1000',
								subCode: 'C1999',
							})}
						>
							기타 편의점
						</li>
					</ul>
				)}
			</div>

			<div className='main-category oil'>
				<div>
					<button
						className={`controller ${codeFilter?.['C4000'] && 'active'}`}
						onClick={onClickFilterButton({ mainCode: 'C4000' })}
					>
						<Oil className='fill' />
					</button>
					<p>
						<em>주유소</em>{codeFilter?.['C4000']?.length > 0 ? '(' + (codeFilter?.['C4000']?.length) + ')' : ''}
					</p>
					<button
						className={`category-btn-open ${open === 'oil' && 'on'}`}
						onClick={() =>
							setOpen((prev) => {
								if (!prev || prev !== 'oil') {
									return 'oil';
								} else {
									return null;
								}
							})
						}
					>
						{open === 'oil' ? <AiFillCaretUp /> : <AiFillCaretDown />}
					</button>
				</div>
				{open === 'oil' && (
					<ul className='sub-category'>
						<li
							className={
								codeFilter?.['C4000']?.includes('C4001') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C4000',
								subCode: 'C4001',
							})}
						>
							GS칼텍스직영
						</li>
						<li
							className={
								codeFilter?.['C4000']?.includes('C4002') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C4000',
								subCode: 'C4002',
							})}
						>
							GS칼텍스
						</li>
						<li
							className={
								codeFilter?.['C4000']?.includes('C4003') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C4000',
								subCode: 'C4003',
							})}
						>
							SK
						</li>
						<li
							className={
								codeFilter?.['C4000']?.includes('C4004') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C4000',
								subCode: 'C4004',
							})}
						>
							현대오일뱅크
						</li>
						<li
							className={
								codeFilter?.['C4000']?.includes('C4005') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C4000',
								subCode: 'C4005',
							})}
						>
							S-OIL
						</li>
						<li
							className={
								codeFilter?.['C4000']?.includes('C4999') ? 'active' : ''
							}
							onClick={onClickFilterButton({
								mainCode: 'C4000',
								subCode: 'C4999',
							})}
						>
							기타 주유소
						</li>
					</ul>
				)}
			</div>
			<div className='main-category side-dish'>
				<div>
					<button
						onClick={onClickFilterButton({ mainCode: 'C3000' })}
						className={`controller ${codeFilter?.['C3000'] && 'active'}`}
					>
						<Food />
					</button>
					<p>
						<em>반찬가게</em>
					</p>
				</div>
			</div>
			<div className='main-category meat'>
				<div>
					<button
						onClick={onClickFilterButton({ mainCode: 'C5000' })}
						className={`controller ${codeFilter?.['C5000'] && 'active'}`}
					>
						<GiMeat />
					</button>
					<p>
						<em>정육점</em>
					</p>
				</div>
			</div>
		</FilterControllerWrap>
	);
});

export default FilterController;

export const FilterControllerWrap = styled.div`
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 50px;
	right: 36px;
	width: 225px;
	background-color: #ffffff;
	border: 1px solid #d1d5db;
	box-sizing: border-box;
	box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	padding: 16px 14px 18px;
	z-index: 500;
	.main-category {
		margin-bottom: 12px;
		> div {
			position: relative;
			display: flex;
			align-items: center;
		}
		> div p {
			font-size: 15px;
			vertical-align: top;
			color: #777777;
			> em {
				font-weight: 600;
				font-size: 15px;
				display: inline-block;
				color: #414141;
				margin-right: 8px;
				vertical-align: top;
			}
		}
		button.controller {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 36px;
			height: 36px;
			background-color: #e2e5e9;
			border-radius: 100%;
			box-sizing: border-box;
			margin-right: 8px;
			svg {
				display: inline-block;
				width: 14px;
				height: 14px;
				&.stroke path {
					stroke: #fff;
				}
				&.fill path {
					fill: #fff;
				}
			}
		}
		&.store {
			/* .category-btn-open.on svg {
				color: #1473e6;
			} */
			.controller.active {
				background-color: #1473e6;
			}
			.sub-category li.active {
				color: #1473e6;
				border-left: 4px solid #1473e6;
				padding-left: 20px;
			}
		}
		&.mart {
			/* .category-btn-open.on svg {
				color: #62d655;
			} */
			.controller.active {
				background-color: #62d655;
			}
			.sub-category li.active {
				color: #62d655;
				border-left: 4px solid #62d655;
				padding-left: 20px;
			}
		}
		&.oil {
			/* .category-btn-open.on svg {
				color: #ff8099;
			} */
			.controller.active {
				background-color: #ff8099;
			}
			.sub-category li.active {
				color: #ff8099;
				border-left: 4px solid #ff8099;
				padding-left: 20px;
			}
		}
		&.side-dish {
			.controller.active {
				background-color: #9868ff;
			}
			.sub-category li.active {
				color: #9868ff;
				border-left: 4px solid #9868ff;
				padding-left: 20px;
			}
		}

		&.meat {
			.controller.active {
				background-color: #f98e40;
			}
			.sub-category li.active {
				color: #f98e40;
				border-left: 4px solid #f98e40;
				padding-left: 20px;
			}
			svg {
				color: #fff;
			}
		}

		.category-btn-open {
			position: absolute;
			padding: 10px 0 10px 10px;
			top: 50%;
			right: 0;
			transform: translate(0, -50%);
			color: #a1a1a1;
		}

		.sub-category {
			margin-top: 4px;
			padding-left: 17px;
			> li {
				cursor: pointer;
				border-left: 2px solid #e0e0e0;
				color: #414141;
				font-size: 15px;
				padding: 8px 0 8px 22px;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;
