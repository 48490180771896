import axios from 'axios';

// const url =
// 	window.location.hostname === 'gsretail-mvp-rfc.azurewebsites.net'
// 		? 'https://gsretail-mvp-rfc-back.azurewebsites.net/api'
// 		: 'http://127.0.0.1:3060/api';

const url =
	window.location.hostname === 'gsretail-mvp-store.azurewebsites.net'
		? 'https://gsretail-mvp-store-back.azurewebsites.net/api'
		: 'http://127.0.0.1:3080/api';

const Api = axios.create({
	baseURL: url,
	withCredentials: true,
});

Api.defaults.withCredentials = true;

Api.interceptors.response.use(
	function (response) {
		/*
        http status가 200인 경우
        응답 성공 직전 호출됩니다. 
        .then() 으로 이어집니다.
    */
		return response;
	},

	function (error) {
		/*
        http status가 200이 아닌 경우
        응답 에러 직전 호출됩니다.
        .catch() 으로 이어집니다.    
    */
		// apiError(error?.response?.data);
		return Promise.reject(error);
	}
);

Api.defaults.headers.post['Content-Type'] = 'application/json';

export default Api;
