import { memo } from 'react';
import styled from 'styled-components';

const DistanceController = memo(({ distance, onClickDistanceButton }) => {
	return (
		<DistanceControllerWrap>
			<ul>
				<li>
					<button
						className={distance === '150' ? 'on' : ''}
						onClick={onClickDistanceButton('150')}
					>
						150M
					</button>
				</li>
				<li>
					<button
						className={distance === '300' ? 'on' : ''}
						onClick={onClickDistanceButton('300')}
					>
						300M
					</button>
				</li>
				<li>
					<button
						className={distance === '500' ? 'on' : ''}
						onClick={onClickDistanceButton('500')}
					>
						500M
					</button>
				</li>
				<li>
					<button
						className={distance === '1000' ? 'on' : ''}
						onClick={onClickDistanceButton('1000')}
					>
						1KM
					</button>
				</li>
			</ul>
		</DistanceControllerWrap>
	);
});

export default DistanceController;

export const DistanceControllerWrap = styled.div`
	position: fixed;
	bottom: 50px;
	right: 36px;
	background-color: #ffffff;
	border: 1px solid #d1d5db;
	box-sizing: border-box;
	box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	overflow: hidden;
	z-index: 500;

	> ul {
		display: flex;
		background: #ffffff;
		border: 1px solid #d3d3d3;
		box-sizing: border-box;
		border-radius: 2px;
	}
	> ul li button {
		display: block;
		width: 56px;
		text-align: center;
		padding: 18px 0 17px;
		border-right: 1px solid #d3d3d3;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		color: #e2e5e9;
		font-weight: bold;
		&.on {
			color: #000000;
		}
	}
	> ul li {
		&:last-child button {
			border-right-width: 0;
		}
	}
`;
