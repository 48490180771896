import styled from 'styled-components';
import PcSidebar from '../../components/sidebar/pcSideBar';
import TabletOrMobileSidebar from '../../components/sidebar/TabletOrMobileSidebar';
// hook
import { OnIsTabletOrMobile } from '../../hook/widthSize';

const Panel = () => {
	const isTabletOrMobile = OnIsTabletOrMobile();
	// useEffect(() => {
	// 	if (!PANEL_ADMIN.includes(accounts[0]['username'])) {
	// 		navigate('/');
	// 	}
	// }, [accounts]);

	return (
		<PanelWrap isTabletOrMobile={isTabletOrMobile}>
			{!isTabletOrMobile ? <PcSidebar /> : <TabletOrMobileSidebar />}
		</PanelWrap>
	);
};

export default Panel;

interface PanelStyledProps {
	isTabletOrMobile: boolean;
}

const PanelWrap = styled.div<PanelStyledProps>`
	width: 100vw;
	height: 100vh;
	${({ isTabletOrMobile }) =>
		isTabletOrMobile ? 'display:block;' : 'display:flex;'}
	.iframe-body {
		${({ isTabletOrMobile }) =>
			isTabletOrMobile ? 'padding: 0;' : 'padding:20px 20px 0;'}
		flex: 1;
		height: calc(100vh - 64px);
	}

	iframe {
		height: 100%;
	}
`;
