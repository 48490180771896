import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
// code
import { SUB_CATEGORY } from '../../data/code';
const BrandDistanceList = ({ handle = false, brand, value }) => {
	const [open, setOpen] = useState(handle);

	const onClickOpen = useCallback(() => {
		setOpen((prev) => !prev);
	}, []);

	const onMouseLeaveList = useCallback(
		(name) => () => {
			const marker = document.getElementById(`${name}`);
			marker && marker.parentElement.classList.remove('hover');
		},
		[]
	);

	const onMouseEnterList = useCallback(
		(name) => () => {
			const marker = document.getElementById(`${name}`);
			marker && marker.parentElement.classList.add('hover');
		},
		[]
	);

	return (
		<BrandDistanceListWrap>
			{value.length > 0 && (
				<div>
					<div className='store-brand-other'>
						<div>
							<h2>
								{SUB_CATEGORY[brand]} ( {value.length} )
							</h2>
							<button onClick={onClickOpen}>
								{open ? <AiFillCaretUp /> : <AiFillCaretDown />}
							</button>
						</div>
						{open && (
							<ul className='store-brand-other-detail'>
								{value.map((store, index) => (
									<li
										key={brand + index}
										onMouseLeave={onMouseLeaveList(store.name)}
										onMouseEnter={onMouseEnterList(store.name)}
									>
										<div>{store.name}</div>
										<span>{store.dst.toLocaleString()} m</span>
									</li>
								))}
							</ul>
						)}
					</div>
				</div>
			)}
		</BrandDistanceListWrap>
	);
};

export default BrandDistanceList;

const BrandDistanceListWrap = styled.div`
	.store-brand-other {
		margin-bottom: 10px;

		> div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px 20px;
			font-weight: bold;
			font-size: 14px;
			border-radius: 8px;
			border: 1px solid #e0e0e0;
		}
	}
	.store-brand-other h2 {
		font-size: 15px;
	}

	.store-brand-other-detail {
		li {
			padding: 14px 20px;
			border-bottom: 1px solid #e0e0e0;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			> span {
				color: #3e85e8;
			}
		}
	}
`;
