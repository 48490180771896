import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMsal } from '@azure/msal-react';
import {
	postStoreDistance,
	postStoreInfo,
	storeDetailReset,
	postFixedDistance,
} from '../../reducers/store';

import FilterController from '../../components/filterController';
import DistanceController from '../../components/distanceController';
import PcSidebar from '../../components/sidebar/pcSideBar';
import Infobar from '../../containers/infobar';
import SearchBar from '../../containers/searchbar';
import PostModal from '../../containers/postModal';
// hooks
import { OnIsTabletOrMobile } from '../../hook/widthSize';

import { IMAGES_CODE } from '../../data/code/index';
import { userInfo } from '../../reducers/user';

const { naver } = window;

// @ts-ignore
const Circle = new naver.maps.Circle({
	// map: map.current,
	// center: new naver.maps.LatLng(store.y, store.x),
	// radius: codeFilterStorage.distance,
	fillOpacity: 0.2,
	strokeOpacity: 0.9,
	fillColor: '#3e85e8',
	strokeColor: '#3e85e8',
});

export const markerImage = (
	category,
	subCategory,
	name,
	dst,
	codeFilter,
	parent,
	child,
	rfc_region_cd,
	oper_region_cd,
	team_mng_yn,
	rfc_yn,
	rfc_adm_yn,
	region_cd
) => {
	const markerDefault = (className, image) => {
		if(team_mng_yn === "Y" && oper_region_cd === region_cd){
			className = className + " rfc";
		}else if(rfc_yn === "Y" && rfc_region_cd === region_cd){
			className = className + " rfc";
		}else if(rfc_adm_yn ==="Y" && rfc_region_cd !== null){
			className = className + " rfc";
		}

		if (image) {
			let el = `<div class="marker-wrap"><button id="${name}" class="marker-button ${className}"><img src=${image} alt=${name}/><p class="marker-button-name">${name} ${
				dst !== '0' ? `[ ${dst}m ]` : ''
			}</p>${
				child.length > 0
					? `<span class="marker-button-count">+${child.length}</span>`
					: ''
			}`;
			el = el + '</button><div>';
			return el;
		}

		let el = `<div class="marker-wrap"><button id="${name}" class="marker-button ${className}"><div class="marker-none-img"></div><p class="marker-button-name">${name} ${
			dst !== '0' ? `[ ${dst.toLocaleString()}m ]` : ''
		}</p>${
			child.length > 0
				? `<span class="marker-button-count">+${child.length}</span>`
				: ''
		}`;
		el = el + '</button></div>';
		return el;
	};

	if (category === '편의점') {
		return markerDefault('store', IMAGES_CODE?.[subCategory]);
	} else if (category === '슈퍼,마트') {
		return markerDefault('mart-station', IMAGES_CODE?.[subCategory]);
	} else if (category === '주유소') {
		return markerDefault('gas-station', IMAGES_CODE?.[subCategory]);
	} else if (category === '반찬가게') {
		return markerDefault('side-dish-station', null);
	} else if (category === '정육점') {
		return markerDefault('meat', null);
	} else {
		return `<div></div>`;
	}
};
 
// type HomePagePropsType = {
// 	setSnackbarMessage: React.Dispatch<string>,
// };

const HomePage = ({ setSnackbarMessage }) => {
	const { accounts } = useMsal();

	const map = useRef();
	const dispatch = useDispatch();
	const { storeList, searchStoreList, storeInfo } = useSelector(
		(state) => state.store
	);
	const isTabletOrMobile = OnIsTabletOrMobile();

	// sidebar
	const [open, setOpen] = useState(true);

	// active store
	const [activeStore, setActiveStore] = useState({
		open: false,
		data: null,
	});

	// active store more
	const [storeMore, setStoreMore] = useState({
		open: false,
		child: null,
		position: null,
	});

	const userInfoData = useSelector((state) => state.user);

	useEffect(() => {
		if(userInfoData?.kor_nm == null) {
			dispatch(userInfo({ account: accounts[0]['username'], kor_nm: accounts[0]['name'] }));
					}
	}, [accounts, dispatch, userInfoData]);

	// modal
	const [postModal, setPostModal] = useState(false);

	// 맵 필터 코드
	const [codeFilter, setCodeFilter] = useState(null);

	// 반경
	const [distance, setDistance] = useState('500');

	// 마커 정보
	const markers = useRef([]);

	useEffect(() => {
		const mvpStoreLocalStorage = localStorage.getItem(
			'MVP-STORE-CONTROLLER-V2.2'
		);

		if (!mvpStoreLocalStorage) {
			localStorage.setItem(
				'MVP-STORE-CONTROLLER-V2.2',
				JSON.stringify({
					mapFilter: {
						C1000: ['C1001', 'C1002', 'C1003', 'C1004'],
						C2000: ['C2001', 'C2003', 'C2005', 'C2008'],
						C3000: null,
						C4000: null,
						C5000: null,
					},
					distance: '500',
				})
			);
			setCodeFilter({
				C1000: ['C1001', 'C1002', 'C1003', 'C1004'],
				C2000: ['C2001', 'C2003', 'C2005', 'C2008'],
				C3000: null,
				C4000: null,
				C5000: null,
			});
			setDistance('500');
		} else {
			const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

			setCodeFilter(mvpStoreLocalStorageJson.mapFilter);
			setDistance(mvpStoreLocalStorageJson.distance);
		}
	}, []);

	useEffect(() => {
		const mvpStoreLocalStorage = localStorage.getItem(
			'MVP-STORE-CONTROLLER-V2.2'
		);

		const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

		map.current = new naver.maps.Map('map', {
			center: new naver.maps.LatLng(37.501921162780974, 127.03726398429752),
			zoom: 18,
		});

		if (navigator?.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const golocationPosition = new naver.maps.LatLng(
						position.coords.latitude,
						position.coords.longitude
					);

					map.current.setCenter(golocationPosition);

					dispatch(
						postStoreDistance({
							lat: position.coords.latitude,
							lng: position.coords.longitude,
							distance: mvpStoreLocalStorageJson.distance,
							account: accounts[0]['username'],
						})
					);
									},
				(error) => {
					// console.log('위치 정보 미동의');
					dispatch(
						postStoreDistance({
							lat: 37.501921162780974,
							lng: 127.03726398429752,
							distance: mvpStoreLocalStorageJson.distance,
							account: accounts[0]['username'],
						})
					);
				// console.error(error);
				},
				{
					enableHighAccuracy: false,
					maximumAge: 0,
					timeout: Infinity,
				}
			);
		} else {
			console.log('GPS를 지원하지 않습니다');

			dispatch(
				postStoreDistance({
					lat: 37.501921162780974,
					lng: 127.03726398429752,
					distance: mvpStoreLocalStorageJson.distance,
					account: accounts[0]['username'],
				})
			);
		}
	}, [accounts, dispatch]);

	const setCircle = useCallback((y, x) => {
		Circle.setCenter(naver.maps.LatLng(y, x));
		Circle.setRadius(distance);
		Circle.setMap(map.current);
	}, [distance, map]);

	const onClickStore = useCallback((store) => {
		dispatch(
			postStoreInfo({
				account: accounts[0]['username'],
				id: store.id,
			})
		);
		dispatch(
			postFixedDistance({
				category: store['category'],
				lat: store.y,
				lng: store.x,
				distance,
				name: store['name'],
				account: accounts[0]['username'],
				codeFilter,
			})
		);
		setActiveStore({ open: true, data: store });
		setCircle(store.y, store.x);
		map.current.setCenter(naver.maps.LatLng(store.y, store.x));
	}, [dispatch, accounts, distance, codeFilter, setCircle]);
	
	useEffect(() => {
		if (storeList) {
			if (markers.current.length > 0) {
				markers.current.forEach((marker) => {
					marker['marker'].setMap(null);
				});
				markers.current = [];
			}

			const proj = map.current.getProjection();

			const overlap = [];
			storeList?.forEach((store, i) => {
				const coord = new naver.maps.LatLng(store.y, store.x);
				const markerOffset = proj.fromCoordToOffset(coord);

				if (
					(store['category'] === '편의점' &&
						codeFilter?.['C1000'] &&
						codeFilter['C1000'].includes(store['sub_category_code'])) ||
					(store['category'] === '슈퍼,마트' &&
						codeFilter?.['C2000'] &&
						codeFilter['C2000'].includes(store['sub_category_code'])) ||
					(store['category'] === '반찬가게' && codeFilter?.['C3000']) ||
					(store['category'] === '주유소' &&
						codeFilter?.['C4000'] &&
						codeFilter['C4000'].includes(store['sub_category_code'])) ||
					(store['category'] === '정육점' && codeFilter?.['C5000'])
				) {
					if (overlap.length <= 0) {
						overlap.push({
							...store,
							coord,
							offset: markerOffset,
							child: [],
						});
					} else {
						const resultIndex = overlap.findIndex(
							(m) =>
								Math.abs(m.offset.x - markerOffset.x) <= 35 &&
								Math.abs(m.offset.y - markerOffset.y) <= 35
						);

						if (resultIndex !== -1) {
							overlap[resultIndex].child.push({ ...store });
						} else {
							overlap.push({
								...store,
								coord,
								offset: markerOffset,
								child: [],
							});
						}
					}
				}
			});

			overlap.forEach((store) => {
				const content = markerImage(
					store['category'],
					store['sub_category'],
					store['name'],
					store['dst'],
					codeFilter,
					store.name,
					store.child,
					store.rfc_region_cd,
					store.oper_region_cd,
					userInfoData?.team_mng_yn,
					userInfoData?.rfc_yn,
					userInfoData?.rfc_adm_yn,
					userInfoData?.region_cd,
				);

				const marker = new naver.maps.Marker({
					map: map.current,
					position: store.coord,
					icon: {
						content: content,
						size: new naver.maps.Size(30, 30),
						anchor: new naver.maps.Point(20, 45),
					},
				});

				markers.current = [...markers.current, { ...store, marker }];

				if (marker) {
					naver.maps.Event.addListener(marker, 'mouseover', function (e) {
						marker.eventTarget.classList.add('hover');
					});

					naver.maps.Event.addListener(marker, 'mouseout', () => {
						marker.eventTarget.classList.remove('hover');
					});

					naver.maps.Event.addListener(marker, 'click', (e) => {
						if (store.child.length <= 0) {
							onClickStore(store);
							setStoreMore({
								open: false,
								child: null,
								position: null,
							});
						} else {
							deleteCircle();
							map.current.setCenter(naver.maps.LatLng(store.y, store.x));

							const marker = document.getElementById(`${store.name}`);
							if (activeStore?.data) {
								marker && marker.parentElement.classList.remove('active');
							}

							const rect = marker.getBoundingClientRect();
							onClickStore(store);
							setStoreMore({
								open: true,
								child: store.child,
								position: { bottom: rect.bottom, left: rect.left },
							});

							// setActiveStore({ open: true, data: store });
						}
					});
				}
			});
		}
	}, [storeList, distance, codeFilter, accounts, activeStore, onClickStore]);

	const onClickStoreDetailClose = useCallback(() => {
		setActiveStore({ open: false, data: null });
		deleteCircle();
		dispatch(storeDetailReset());
		if (activeStore?.data) {
			const marker = document.getElementById(`${activeStore.data.name}`);
			marker && marker.parentElement.classList.remove('active');
		}
	}, [activeStore.data, dispatch]);

	const onChangeZoom = useCallback((e) => {
		setStoreMore({
			open: false,
			child: null,
			position: null,
		});
		if (e <= 10) {
			if (markers.current.length > 0) {
				markers.current.forEach((marker) => {
					marker['marker'].setMap(null);
				});
				markers.current = [];
			}
			onClickStoreDetailClose();
		} else if (!Circle.getMap()) {
			const position = map.current.getCenter();

			dispatch(
				postStoreDistance({
					lat: position.y,
					lng: position.x,
					distance,
					account: accounts[0]['username'],
				})
			);
		}
	}, [accounts, dispatch, distance, onClickStoreDetailClose]);

	const onChangeDragend = useCallback((e) => {
		if (map.current.getZoom() > 10 && !Circle.getMap()) {
			const position = map.current.getCenter();
			
			dispatch(
				postStoreDistance({
					lat: position.y,
					lng: position.x,
					distance,
					account: accounts[0]['username'],
				})
			);
		}
	}, [accounts, dispatch, distance]);
	
	useEffect(() => {
		const dragStartEvent = naver.maps.Event.addListener(
			map.current,
			'dragstart',
			onDragStart
		);
		const centerEvent = naver.maps.Event.addListener(
			map.current,
			'dragend',
			onChangeDragend
		);
		const zoomEvent = naver.maps.Event.addListener(
			map.current,
			'zoom_changed',
			onChangeZoom
		);
		const sizeEvent = naver.maps.Event.addListener(
			map.current,
			'size_changed',
			onChangeSize
		);
		return () => {
			naver.maps.Event.removeListener(centerEvent);
			naver.maps.Event.removeListener(zoomEvent);
			naver.maps.Event.removeListener(sizeEvent);
			naver.maps.Event.removeListener(dragStartEvent);
		};
	}, [accounts, distance, onChangeDragend, onChangeZoom]);

	const onDragStart = (e) => {
		setStoreMore({
			open: false,
			child: null,
			position: null,
		});
	};

	const onChangeSize = (e) => {
		setStoreMore({
			open: false,
			child: null,
			position: null,
		});
		map.current.autoResize();
	};

	const deleteCircle = (y, x) => {
		Circle.setMap(null);
	};

	useEffect(() => {
		map.current.autoResize();
	}, [activeStore.open]);

	useEffect(() => {
		if (activeStore.data) {
			const marker = document.getElementById(`${activeStore['data']['name']}`);
			marker && marker.parentElement.classList.add('active');
		}
	}, [activeStore, storeList]);

	const onClickFilterButton =
		({ mainCode, subCode }) =>
		(e) => {
			e.stopPropagation();
			const mvpStoreLocalStorage = localStorage.getItem(
				'MVP-STORE-CONTROLLER-V2.2'
			);
			const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

			let newMapFilter = {};

			if (mvpStoreLocalStorageJson.mapFilter?.[mainCode]) {
				if (subCode) {
					newMapFilter = {
						...mvpStoreLocalStorageJson.mapFilter,
						[mainCode]: mvpStoreLocalStorageJson.mapFilter[mainCode].includes(
							subCode
						)
							? mvpStoreLocalStorageJson.mapFilter[mainCode]?.length > 1
								? mvpStoreLocalStorageJson.mapFilter[mainCode].filter(
										(s) => s !== subCode
								  )
								: null
							: [...mvpStoreLocalStorageJson.mapFilter[mainCode], subCode],
					};
				} else {
					newMapFilter = { ...mvpStoreLocalStorageJson.mapFilter };
					delete newMapFilter[mainCode];
				}
			} else {
				if (!subCode) {
					newMapFilter = {
						...mvpStoreLocalStorageJson.mapFilter,
						[mainCode]:
							mainCode === 'C1000'
								? ['C1001', 'C1002', 'C1003', 'C1004', 'C1999']
								: mainCode === 'C2000'
								? [
										'C2001',
										'C2003',
										'C2005',
										'C2006',
										'C2008',
										'C2011',
										'C2012',
										'C2999',
								  ]
								: mainCode === 'C3000'
								? ['C3001']
								: mainCode === 'C4000'
								? ['C4001', 'C4002', 'C4003', 'C4004', 'C4005', 'C4999']
								: mainCode === 'C5000'
								? ['C5001']
								: null,
					};
				} else {
					newMapFilter = {
						...mvpStoreLocalStorageJson.mapFilter,
						[mainCode]: [subCode],
					};
				}
			}

			const newMvpStoreController = {
				...mvpStoreLocalStorageJson,
				mapFilter: newMapFilter,
			};

			localStorage.setItem(
				'MVP-STORE-CONTROLLER-V2.2',
				JSON.stringify(newMvpStoreController)
			);

			setCodeFilter(newMapFilter);
		};

	const onClickDistanceButton = (distance) => () => {
		const newCodeFilter = {
			mapFilter: codeFilter,
			distance,
		};

		if (!activeStore.open) {
			const position = map.current.getCenter();
						dispatch(
				postStoreDistance({
					lat: position.y,
					lng: position.x,
					distance,
					account: accounts[0]['username'],
				})
			);
		} else {
			const position = Circle.getCenter();
			Circle.setRadius(distance);
			dispatch(
				postFixedDistance({
					lat: position.y,
					lng: position.x,
					distance,
					account: accounts[0]['username'],
					name: activeStore['data']['name'],
					codeFilter,
				})
			);
		}

		localStorage.setItem(
			'MVP-STORE-CONTROLLER-V2.2',
			JSON.stringify(newCodeFilter)
		);

		setDistance(distance);
	};

	const onChangeSideBar = () => {
		setOpen((prev) => !prev);
		const timeOut = setTimeout(() => {
			map.current.autoResize();
			clearTimeout(timeOut);
		}, 350);
	};

	return (
		<HomeWrap isTabletOrMobile={isTabletOrMobile}>
			<div className='left-container'>
				<PcSidebar
					map={map}
					open={open}
					setOpen={setOpen}
					onChangeSideBar={onChangeSideBar}
					codeFilter={codeFilter}
					onClickFilterButton={onClickFilterButton}
					isTabletOrMobile={isTabletOrMobile}
					distance={distance}
					onClickDistanceButton={onClickDistanceButton}
				>
					<SearchBar
						map={map}
						searchStoreList={searchStoreList}
						defaultList={storeList}
						onClickStore={onClickStore}
						account={accounts[0]['username']}
						onChangeSideBar={onChangeSideBar}
						searchCodeFilter={codeFilter}
						setSnackbarMessage={setSnackbarMessage}
						// onClickSearchFilterButton={onClickSearchFilterButton}
					/>
				</PcSidebar>

				{activeStore.open && !isTabletOrMobile && (
					<Infobar
						user={accounts[0]['name']}
						activeStore={activeStore.data}
						close={onClickStoreDetailClose}
					/>
				)}
			</div>
			<div id='map' style={{ width: '100%', height: '100%' }} />

			{!isTabletOrMobile && (
				<>
					<FilterController
						codeFilter={codeFilter}
						onClickFilterButton={onClickFilterButton}
					/>
					<DistanceController
						distance={distance}
						onClickDistanceButton={onClickDistanceButton}
					/>
				</>
			)}

			<PostModal
				user={accounts[0]['name']}
				postModal={postModal}
				setPostModal={setPostModal}
				storeDetail={storeInfo}
			/>

			{storeMore.open && (
				<StoreMoreListWrap
					left={storeMore.position.left}
					bottom={storeMore.position.bottom}
				>
					<ul>
						{storeMore.child.map((store, i) => (
							<li
								key={store.name + i}
								className='marker-button-child-item'
								onClick={() => {
									onClickStore(store);
								}}
							>
								{store.name}
							</li>
						))}
					</ul>
				</StoreMoreListWrap>
			)}
		</HomeWrap>
	);
};

export default HomePage;

export const StoreMoreListWrap = styled.div`
	position: fixed;
	bottom: ${({ bottom }) => (bottom ? `calc(${bottom + 40}px);` : '0;')};
	left: ${({ left }) => (left ? `${left + 2}px;` : '0;')};
	margin-bottom: 8px;
	z-index: 1000;
	> ul {
		max-height: 270px;
		overflow-y: auto;
		background-color: #fff;
		border: 1px solid rgba(0, 0, 0, 0.05);
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
			background: #ffffff;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 3.5px;
			background-color: #ced4da;

			&:hover {
				background-color: #adb5bd;
			}
		}
		&::-webkit-scrollbar-track {
			background: #ffffff;
		}

		> li {
			padding: 10px 28px;
			white-space: nowrap;
			border-bottom: 1px solid #ecf0f2;
			text-align: left;
			color: #333;
			font-family: 'Noto Sans KR', sans-serif !important;
			cursor: pointer;
			&:last-child {
				border-width: 0;
			}
		}
	}
`;

export const HomeWrap = styled.div`
	display: flex;
	flex-direction: ${({ isTabletOrMobile }) =>
		!isTabletOrMobile ? 'row;' : 'column;'};

	height: 100%;

	.store-detail,
	.caltex-store-detail {
		position: relative;
		border-left: 1px solid #d9d9d9;
		box-sizing: border-box;
		width: 360px;
		height: 100%;
	}

	.caltex-store-detail {
		> div:first-child {
			height: 100%;
			overflow-y: auto;
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			&::-webkit-scrollbar {
				display: none; /* Chrome, Safari, Opera*/
			}
		}
	}

	.close-wrap {
		position: absolute;
		z-index: 15;
		top: 30px;
		left: 100%;
		box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%), 5px 0 15px 0 rgb(0 0 0 / 10%);
		border-radius: 0 5px 5px 0;
		overflow: hidden;
		button {
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #e0e0e0;
			border-left-width: 0;
		}
		svg {
			width: 24px;
			height: 24px;
		}
	}

	.left-container {
		box-sizing: border-box;
		position: relative;
		display: flex;
		z-index: 10;
		box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%), 5px 0 15px 0 rgb(0 0 0 / 10%);
	}

	.store-detail-default {
		/* margin-bottom: 30px; */
		padding: 30px 20px;
		border-bottom: 6px solid #f0f2f5;
	}
	.store-detail-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;
	}

	.store-detail-title h1 {
		font-size: 24px;
	}
	.store-detail-title button svg {
		width: 22px;
		height: 22px;

		path {
			color: #d9d9d9;
		}
	}

	.store-detail-title button.on svg path {
		color: #ffeb5a;
	}

	.store-admin {
		/* padding: 30px 0; */
		border-bottom: 6px solid #f0f2f5;
		> div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 16px;
			border-bottom: 1px solid #e0e0e0;
		}
	}

	.custom-infow-window {
		background-color: #fff;
		color: #333;
		padding: 5px;
		border: #e0e0e0;
		border-radius: 5px;
		box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%), 5px 0 15px 0 rgb(0 0 0 / 10%);
	}

	.label-textarea {
		display: block;
		font-size: 14px;
		color: #757575;
		margin-bottom: 8px;
	}

	#map {
		flex: 1;
	}

	#map .marker-wrap {
		padding-top: 8px;
		&.active .marker-button,
		&.hover .marker-button {
			padding: 4px 8px 4px 4px;
			z-index: 20;
		}
		&.active .marker-button > .marker-none-img,
		&.hover .marker-button > .marker-none-img {
			margin-right: 6px;
		}
	}

	#map .marker-button {
		position: relative;
		display: flex;
		padding: 4px;
		border-radius: 23px;
		background: #fff;
		align-items: center;
		white-space: nowrap;

		& > .marker-none-img {
			width: 30px;
			height: 30px;
			border-radius: 100%;
			background-color: #ef7300;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		& > img {
			width: 30px;
		}
	}

	#map .marker-button.store {
		border: 1px solid #3e85e8;
		& .marker-none-img {
			background-color: #3e85e8;
		}
		.marker-button-count {
			border: 1px solid #3e85e8;
			color: #3e85e8;
		}
	}

	#map .marker-button.store.rfc {
		border: 2px solid rgba(242,101,34, 0.7);
		& .marker-none-img {
			background-color: rgba(242,101,34, 0.7);
		}
		.marker-button-count {
			border: 1px solid rgba(242,101,34, 1);
			color: rgba(242,101,34, 1);
		}
	}

	#map .marker-button.gas-station {
		border: 1px solid #ff8099;
		z-index: 10;
		&:after {
			box-shadow: -1px 1px 1px 0 #ff8099;
		}
		& .marker-none-img {
			background-color: #ff8099;
		}
		.marker-button-count {
			border: 1px solid #ff8099;
			color: #ff8099;
		}
	}

	#map .marker-button.mart-station {
		border: 1px solid #62d655;
		&:after {
			box-shadow: -1px 1px 1px 0 #62d655;
		}
		& .marker-none-img {
			background-color: #62d655;
		}
		.marker-button-count {
			border: 1px solid #62d655;
			color: #62d655;
		}
	}

	#map .marker-button.side-dish-station {
		border: 1px solid #9868ff;
		&:after {
			box-shadow: -1px 1px 1px 0 #9868ff;
		}
		& .marker-none-img {
			background-color: #9868ff;
		}
		.marker-button-count {
			border: 1px solid #9868ff;
			color: #9868ff;
		}
	}

	#map .marker-button.meat {
		border: 1px solid #f98e40;
		&:after {
			box-shadow: -1px 1px 1px 0 #f98e40;
		}
		& .marker-none-img {
			background-color: #f98e40;
		}
		.marker-button-count {
			border: 1px solid #f98e40;
			color: #f98e40;
		}
	}

	#map .marker-button:after {
		background: linear-gradient(
			45deg,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 0) 51%,
			rgba(255, 255, 255, 0) 100%
		);
		box-shadow: -1px 1px 1px 0 #0475f4;
		content: '';
		height: 9px;
		left: 19px;
		position: absolute;
		top: 37px;
		transform: translate(-50%, -50%) rotate(-45deg);
		width: 9px;
	}

	#map .marker-button.store.rfc:after {
		background: linear-gradient(
			45deg,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 0) 51%,
			rgba(255, 255, 255, 0) 100%
		);
		box-shadow: -2px 2px 1px rgba(242,101,34, 0.7);
		content: '';
		height: 9px;
		left: 19px;
		position: absolute;
		top: 37px;
		transform: translate(-50%, -50%) rotate(-45deg);
		width: 9px;
	}

	#map .marker-wrap.active img,
	#map .marker-wrap.hover img {
		margin-right: 6px;
	}
	#map .marker-button .marker-button-name {
		/* width: 0;
		overflow: hidden; */
		display: none;
		color: #333;
		opacity: 0;
		font-family: 'Noto Sans KR', sans-serif !important;
	}
	#map .marker-wrap.active .marker-button .marker-button-name,
	#map .marker-wrap.hover .marker-button .marker-button-name {
		/* transition: all 400ms; */
		/* width: 100%; */
		display: inline-block;
		opacity: 1;
	}

	#map .marker-button-count {
		width: 20px;
		height: 15px;
		position: absolute;
		top: -6px;
		right: -4px;
		border-radius: 9px;
		background-color: #fff;
		vertical-align: middle;
		text-align: center;
		font-size: 11px;
		line-height: 15px;
		font-weight: bold;
	}
`;
