import styled from 'styled-components';
import { use100vh } from 'react-div-100vh';
// components
import { SignInButton } from '../../components/signinButton';

const SignIn = () => {
	const height = use100vh();
	return (
		<SignInWrap height={height}>
			<div>
				<img alt='gs' src='/gsretail-logo.png' width='350px' />
				<SignInButton />
			</div>
		</SignInWrap>
	);
};

export default SignIn;

type SignInWrapType = {
	height: number | null;
};

const SignInWrap = styled.div<SignInWrapType>`
	display: flex;
	height: ${({ height }) => (height ? `${height}px;` : '100%;')}
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 20px;

	> div {
		padding-top: 100px;
	}

	img {
		margin-bottom: 150px;
	}
`;
