import { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { store } from './store';
import Home from './pages/Home';
import MobileHome from './pages/Home/mobileHome';
import Report from './pages/report';
import Panel from './pages/panel';
import SignIn from './pages/signIn';
import { useIsAuthenticated } from '@azure/msal-react';
import Snackbar from './components/snackbar';
// hook
import { OnIsTabletOrMobile } from './hook/widthSize';

function App() {
	const isAuthenticated = useIsAuthenticated();
	const isTabletOrMobile = OnIsTabletOrMobile();

	const [snackbarMessage, setSnackbarMessage] = useState<string>('');

	return (
		<Provider store={store}>
			{!isAuthenticated ? (
				<SignIn />
			) : (
				<BrowserRouter>
					<Routes>
						{isTabletOrMobile ? (
							<Route
								path='/'
								element={<MobileHome setSnackbarMessage={setSnackbarMessage} />}
							/>
						) : (
							<Route
								path='/'
								element={<Home setSnackbarMessage={setSnackbarMessage} />}
							/>
						)}

						<Route path='/report' element={<Report />} />
						<Route path='/panel' element={<Panel />} />
					</Routes>
				</BrowserRouter>
			)}

			<Snackbar message={snackbarMessage} />
		</Provider>
	);
}

export default App;
