import { memo, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postStoreInfo } from '../../reducers/store';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/ko';
import BrandDistanceList from '../../components/brandDistanceList';
import Select from '../../components/select';
// icon
import { RiPushpin2Fill } from 'react-icons/ri';
import { AiOutlineExclamation } from 'react-icons/ai';
import { ReactComponent as Close } from '../../assets/close.svg';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { userInfo } from '../../reducers/user';
import CircularProgress from '@mui/material/CircularProgress';

function formatToIntegerWithCommas(numberString) {
  if (numberString == null || Math.floor(parseFloat(numberString)) === 0) {
    return '-';
  }else{
    let number = parseFloat(numberString);
    let integerNumber = Math.floor(number);
    let formattedNumber = integerNumber.toLocaleString('en-US');
    return formattedNumber;
  }
}

const Infobar = memo(({ activeStore, close, user }) => {
	const { accounts } = useMsal();

	const {
		storeReviewList,
		storeInfo,
		fixedStoreFilterList,
		storekeywordStat,
    	areaInfo,
    	areaInfoDetail,
		reviewDate,
		status,
	} = useSelector((state) => state.store);

	const userInfoData = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const [active, setActive] = useState('competitor');
	const infoEl = useRef(null);
	const infoDefaultEl = useRef(null);
	const infoDetailEl = useRef(null);

	const review_category = ["C1001", "C2001"];
	const competition_category = ["C1002", "C1003", "C1004"];

	const onClickActive = useCallback(
		(handle) => () => {
			infoDetailEl.current.scrollTop = 0;
			setActive(handle);
		},
		[]
	);

	const onChangeReview = (reviewDate) => () => {
		dispatch(
			postStoreInfo({
				account: user,
				id: activeStore.id,
				date: reviewDate,
			})
		);
	};

	/*
		네이버 리뷰 조회 권한
	*/
	const checkAuthNaverReview = (sub_category_code) => {
		if(review_category.includes(sub_category_code)){
			return true;
		}else{
			return false;
		}
	}

	/* 영업팀 정보 조회 권한
		- 자사 편의점: 개발팀 제외하고 전체	 (sub_category_code === "C1001" && rfc_yn !== "Y")
		- 자사 수퍼: 전체					(sub_category_code === "C2001")
	*/
	const checkAuthOperTeamInfo = (sub_category_code, rfc_yn) => {
		if(sub_category_code === "C1001" && rfc_yn !== "Y"){
			return true;
		}else if(sub_category_code === "C2001"){
			return true;
		}else{
			return false;
		}
	}

	/* 개발팀 정보 조회 권한
		- 자사 편의점: 개발팀만
		- 타사 편의점: 영업팀 & 개발팀
	*/
	const checkAuthRFCTeamInfo = (sub_category_code, team_mng_yn, part_mng_yn, rfc_yn) => {
		if(sub_category_code === "C1001" && rfc_yn === "Y"){
			return true;
		}else if(competition_category.includes(sub_category_code) && (rfc_yn === "Y" || team_mng_yn === "Y" || part_mng_yn === "Y")){
			return true;
		}else{
			return false;
		}
	}

	/* OFC/점장 정보 조회 권한
		- 자사 편의점/수퍼 전체
	*/
	const checkManagerInfo = (sub_category_code) => {
		if(review_category.includes(sub_category_code)){
			return true;
		}else{
			return false;
		}
	}

	/* 예상매출액 정보 조회 권한
		- 편의점 영업팀장: 소속지역팀 타사 편의점
		- 개발팀: 소속지역팀 편의점 전체
		- 개발기획: 편의점 전체
	*/
	const checkExpAmountInfo = (sub_category_code, team_mng_yn, oper_region_cd, rfc_yn, rfc_region_cd, rfc_adm_yn, region_cd) => {
		if(competition_category.includes(sub_category_code) && team_mng_yn === "Y" && oper_region_cd === region_cd){
			return true;
		}else if(competition_category.includes(sub_category_code) && rfc_yn === "Y" && rfc_region_cd === region_cd){
			return true;
		}else if(competition_category.includes(sub_category_code) && rfc_adm_yn === "Y"){
			return true;
		}else{
			return false;
		}
	}

	const checkStoreStatusInfo = (sub_category_code, team_mng_yn, oper_region_cd, rfc_yn, rfc_region_cd, rfc_adm_yn, region_cd) => {
		if((sub_category_code === "C1001" || competition_category.includes(sub_category_code)) && team_mng_yn === "Y" && oper_region_cd === region_cd){
			return true;
		}else if((sub_category_code === "C1001" || competition_category.includes(sub_category_code)) && rfc_yn === "Y" && rfc_region_cd === region_cd){
			return true;
		}else if((sub_category_code === "C1001" || competition_category.includes(sub_category_code)) && rfc_adm_yn === "Y"){
			return true;
		}else{
			return false;
		}
	}

	useEffect(() => {
		if(userInfoData?.kor_nm == null || userInfoData === undefined) {
			dispatch(userInfo({ account: accounts[0]['username'], kor_nm: accounts[0]['name'] }));
		}else{
			console.log("userInfoData", userInfoData);
		}
	}, [accounts, dispatch, userInfoData]);

	useEffect(() => {
		if(status == null || status === undefined){
			dispatch(
				postStoreInfo({
					account: user,
					id: activeStore.id,
					date: reviewDate,
				})
			);
		}else{
			if(checkStoreStatusInfo(activeStore?.["sub_category_code"], userInfoData?.team_mng_yn, activeStore?.["oper_region_cd"], userInfoData?.rfc_yn, activeStore?.["rfc_region_cd"], userInfoData?.rfc_adm_yn, userInfoData?.region_cd)){
				setActive("status");
			}else{
				setActive("competitor");
			}
		}
	}, [activeStore.id, dispatch, reviewDate, status, user]);

	return (
    	<InfobarWrap ref={infoEl}>
			<div className="info-default">
			<div className="info-default-header">
				<button onClick={close}>
					<Close />
				</button>
			</div>
			<div ref={infoDefaultEl}>
				<h1>
					{activeStore.name}
					<em>
						<RiPushpin2Fill />
					</em>
				</h1>
				<p>{activeStore.category}</p>
				<p>{activeStore.phone}</p>
				<p>{activeStore.full_address}</p>
			
				<ul className="info-default-manager">
					{checkAuthOperTeamInfo(activeStore?.["sub_category_code"], userInfoData?.rfc_yn) && (
					<li>
						<span>영업팀</span>
						<Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>{storeInfo?.["div_name"]}</Typography>
					</li>
					)}
					{checkAuthRFCTeamInfo(activeStore?.["sub_category_code"], userInfoData?.team_mng_yn, userInfoData?.part_mng_yn, userInfoData?.rfc_yn) && (
					<li>
						<span>개발팀</span>
						<Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>{areaInfo == null ? '-': areaInfo["rfc_region_nm"]}</Typography>
					</li>
					)}
					{checkManagerInfo(activeStore?.["sub_category_code"]) && (
					<li>
						<span>OFC/점장</span>
						<Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>{storeInfo?.["kornm"]}</Typography>
					</li>
					)}
					{checkExpAmountInfo(activeStore?.["sub_category_code"], userInfoData?.team_mng_yn, activeStore?.["oper_region_cd"], userInfoData?.rfc_yn, activeStore?.["rfc_region_cd"], userInfoData?.rfc_adm_yn, userInfoData?.region_cd) && (
					<li>
						<span>예상 일매출</span>
						<Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>
							{areaInfo == null ? '-'
								: (
										areaInfo["signal"] === '-' 
											? formatToIntegerWithCommas(areaInfo["exp_sales_amount"])
											: (
													areaInfo["signal"] ==='UP' 
													? formatToIntegerWithCommas(areaInfo["exp_sales_amount"]) + ' (▲' + formatToIntegerWithCommas(areaInfo["exp_sales_amount_pre"]) + ')'
													:	(areaInfo["signal"] ==='DOWN'
															? formatToIntegerWithCommas(areaInfo["exp_sales_amount"]) + ' (▼' + formatToIntegerWithCommas(areaInfo["exp_sales_amount_pre"]) + ')'
															: formatToIntegerWithCommas(areaInfo["exp_sales_amount"])
														)
												)
									)}
						</Typography>
					</li>
					)}
				</ul>
				</div>
			</div>
			<ul className="info-detail-header">
				<li>
					<button className={active === "competitor" ? "active" : ""} onClick={onClickActive("competitor")}>주변 점포</button>
				</li>
				{checkAuthNaverReview(activeStore?.["sub_category_code"]) && (
				<li>
					<button className={active === "review" ? "active" : ""} onClick={onClickActive("review")}>네이버 리뷰</button>
				</li>
				)}
				{checkStoreStatusInfo(activeStore?.["sub_category_code"], userInfoData?.team_mng_yn, activeStore?.["oper_region_cd"], userInfoData?.rfc_yn, activeStore?.["rfc_region_cd"], userInfoData?.rfc_adm_yn, userInfoData?.region_cd) && (
				<li>
					<button className={active === "status" ? "active" : ""} onClick={onClickActive("status")}>점포 현황</button>
				</li>
				)}
			</ul>
			<div className="info-detail" ref={infoDetailEl}>
				<div className="info-detail-body">
				{active === "status" ? (
					<>
						<Typography sx={{ fontSize: 13, mb:"15px", color: "grey" }}>※주거/인구/교통/학군/상권 정보는 부동산114 제공 정보입니다.</Typography>
						{status === null ? <CircularProgress /> : (status === "200" && areaInfoDetail?.length > 0 ? 
							
							(areaInfoDetail.map((cat, index) => (
								<div key={index}>
									<Typography key={index} sx={{ fontSize: 15, fontWeight: "bold" }}>{cat["category_name"]}</Typography>
									<CardContent sx={{bgcolor: "#F0F0F0", borderRadius: "10px", marginBottom: "20px"}}>
										{cat["info_detail"].map((info, index) => (
											<Grid item xs={12} sm container key={index}>
												<Grid item xs={7}>
													<Typography variant="body2">{info["code_name"] }</Typography>
												</Grid>
												{areaInfo["signal"] !== '-' && (
													<Grid item xs={2.5} sx={{ textAlign: "right" }}>
														<Typography variant="body2" sx={{ fontWeight: "bold" }}>
															{	info["signal"] === 'UP' 
																? '(▲' + formatToIntegerWithCommas(info["code_value_pre"]) + ')'
																:	(info["signal"] ==='DOWN'
																		?  '(▼' + formatToIntegerWithCommas(info["code_value_pre"]) + ')'
																		: info["signal"] === "DIFF" 
																				? info["code_value_pre"] + " → " 
																				: ""
																	)
															}
														</Typography>
													</Grid>)}
												<Grid item xs={(areaInfo["signal"] === '-' ? 5 : 2.5)} sx={{ textAlign: "right" }}>
													<Typography variant="body2" sx={{ fontWeight: "bold" }}>
														{info["code_value"] == null ? '-': (info["code_type"] === "INT" ? formatToIntegerWithCommas(info["code_value"]): info["code_value"])}
													</Typography>
												</Grid>
											</Grid>
										))}
									</CardContent>
								</div>
							))
						)
						: ((status === "200" && areaInfoDetail?.length === 0 ? 
								<CardContent sx={{bgcolor: "#F0F0F0", borderRadius: "10px", marginBottom: "20px", textAlign: 'center'}}>
									<Typography sx={{ fontSize: 15, fontWeight: "bold", marginTop:"10px" }}>점포현황 등록 내역이 없습니다</Typography>
								</CardContent>
								: null)
						))}
					</>
				) : active === "competitor" ? (
					<>
						{fixedStoreFilterList && Object.entries(fixedStoreFilterList).map(([key, value]) => (
							<BrandDistanceList key={key} brand={key} value={value}/>
						))}
					</>
				) : active === "review" ? (
					<>
						<div className="info-detail-keyword">
							<div className="info-detail-keyword-header">
								<h2>키워드 리뷰{" "}
									<em>{storekeywordStat?.[0]?.["totCnt"] ? storekeywordStat[0]["totCnt"] : "0"}</em>
								</h2>
								<Select value={reviewDate}>
									<li className={reviewDate === "1 개월" ? "active" : ""} onClick={onChangeReview("1 개월")}>1 개월</li>
									<li className={reviewDate === "3 개월" ? "active" : ""} onClick={onChangeReview("3 개월")}>3 개월</li>
									<li className={reviewDate === "6 개월" ? "active" : ""} onClick={onChangeReview("6 개월")}>6 개월</li>
								</Select>
							</div>
							{storekeywordStat?.length > 0 && (
								<ul className="info-detail-keyword-list">
									{storekeywordStat.map((keyword, i) => (
										<InfoKeywordBarWrap key={"keyword-list" + i} per={keyword.per}>
											<p>{keyword["display_name"]}</p>
											<span>{keyword["cnt"]}</span>
										</InfoKeywordBarWrap>
									))}
								</ul>
							)}
						</div>
						<div className="info-detail-review">
							<ul>
								{storeReviewList?.length > 0 ? 
									(
										storeReviewList.map((review, index) => (
											<li key={review["review_id"] + index}>
												<p className="reviewer">{review["author_nickname"]}</p>
												<p className="body">{review["body"]}</p>
												<VotedkeywordsWrap>
													{review["voted_keywords"]?.map((vote, i) => (
														<li key={review["review_id"] + index + i}>
															{vote}
														</li>
													))}
												</VotedkeywordsWrap>
												<p className="rest">
													방문일 :{" "} {moment(review["author_visited"]).format("MMM Do")} ·
													작성일 :{" "} {moment(review["author_created"]).format("MMM Do")}
												</p>
											</li>
										))
									) 
									: (
										<li className="info-detail-review-none">
											<div>
												<div className="info-detail-review-none-icon">
													<AiOutlineExclamation />
												</div>
												<p>최근 {reviewDate} 수집된 리뷰 데이터가 없습니다.</p>
											</div>
										</li>
									)}
							</ul>
						</div>
					</>
				) : null}
				</div>
			</div>
		</InfobarWrap>
  );
});

export default Infobar;

const InfoKeywordBarWrap = styled.li`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
	border: 1px solid #d1d5db;
	border-radius: 8px;
	margin-bottom: 5px;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: ${({ per }) => (per ? `${per}%;` : '0;')};
		height: 41px;
		background-color: #dae9fc;
		z-index: 10px;
	}

	&:last-child {
		margin-bottom: 0;
	}
	> p {
		font-weight: 500;
		font-size: 14px;
		z-index: 30;
	}
	> span {
		color: #1473e6;
		font-weight: 600;
		font-size: 14px;
		z-index: 30;
	}
`;

const InfobarWrap = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 460px;
	box-sizing: border-box;
	padding: 26px 20px 0;
	border-left: 1px solid #e5e7eb;

	.info-detail {
		flex: 1;
		overflow-y: auto;
		margin-right: -20px;
		padding-right: 20px;
		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
			background: #ffffff;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 3.5px;
			background-color: #ced4da;

			&:hover {
				background-color: #adb5bd;
			}
		}
		&::-webkit-scrollbar-track {
			background: #ffffff;
		}
	}

	.info-detail-header {
		display: flex;
		align-items: center;
		border-top: 1px solid #d1d5db;
		border-bottom: 1px solid #d1d5db;
		box-sizing: border-box;
		background: #fff;
		margin-bottom: 28px;
		&.on {
			position: fixed;
			top: 53px;
			width: 360px;
			margin-left: -20px;
			z-index: 50;
			border-top-width: 0;
		}

		> li {
			flex: 1;
			text-align: center;
		}

    > li .info-li-left{
			flex: 1;
			text-align: left;
		}
    
		> li button {
			box-sizing: border-box;
			font-size: 16px;
			padding: 14px 0;
			&.active {
				border-bottom: 3px solid #1473e6;
				padding: 14px 0 11px;
				font-weight: 700;
			}
		}
	}

	/* .info-detail-review {
		padding-top: 12px;
	} */

	.info-detail-review > ul li {
		border-bottom: 1px solid #e5e7eb;
		padding: 12px 0;
	}

	.info-detail-review .reviewer {
		font-size: 14px;
		line-height: 20px;
		font-weight: 700;
	}
	.info-detail-review .body {
		font-size: 12px;
		line-height: 20px;
		font-weight: 400;
	}

	.info-detail-review .rest {
		font-size: 12px;
		line-height: 20px;
		font-weight: 400;
		color: #727272;
	}

	.info-detail-review-none {
		padding: 50px 0 !important;
		.info-detail-review-none-icon {
			display: inline-block;
			border-radius: 100%;
			border: 2px solid #666;
			margin-bottom: 20px;
			> svg {
				font-size: 50px;
				color: #666;
			}
		}
		text-align: center;
	}

	.info-detail-keyword {
		padding-bottom: 22px;
		border-bottom: 1px solid #e5e7eb;
	}

	.info-detail-keyword-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> h2 {
			font-weight: bold;
			font-size: 18px;
			line-height: 45px;
			vertical-align: middle;
		}
		> h2 em {
			font-weight: 500;
			font-size: 18px;
			line-height: 45px;
			color: #1473e6;
			vertical-align: bottom;
		}

		.select-wrap {
			border-width: 0;
			padding: 0;
			> p {
				margin: 0 4px;
			}
			> ul {
				top: calc(100% - 4px);
			}
		}
	}

	.info-default {
		margin-bottom: 28px;
	}

	.info-default h1 {
		font-weight: bold;
		font-size: 25px;
		line-height: 30px;
		vertical-align: top;
		margin-bottom: 24px;
		background: #fff;
		box-sizing: border-box;
		position: relative;
		padding-right: 26px;
		&.on {
			position: fixed;
			top: 0;
			padding: 14px 20px;
			width: 360px;
			margin-left: -20px;
			z-index: 50;
			border-bottom: 1px solid #d1d5db;
			margin-bottom: 0;
		}
	}
	.info-default h1 em {
		position: absolute;
		top: 0;
		right: 0;
		color: #1473e6;
		font-weight: 400;
		font-size: 25px;
		vertical-align: top;
		svg {
			margin-top: -5px;
			margin-left: 6px;
		}
	}

	.info-default p {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}

	.info-default .info-default-manager {
		display: flex;
		align-items: top;
		justify-content: space-between;
		margin: 20px -4.5px 0;

		> li {
			flex: 1;
			border: 1px solid #d1d5db;
			box-sizing: border-box;
			border-radius: 8px;
			margin: 0 4.5px;
			text-align: center;
			padding: 4px 4px 6px;
			overflow: hidden;
		}
		> li span {
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
		}
		> li p {
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
			/* overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap; */
		}
	}

	.info-default-header {
		text-align: right;
		margin-bottom: 20px;

		button {
			margin-left: 25px;
		}

		svg.ri {
			width: 26px;
			height: 26px;
		}
	}

	svg {
		vertical-align: middle;
	}
`;

const VotedkeywordsWrap = styled.ul`
	li {
		padding: 5px 20px;
		background: #ffffff;
		border: 0.5px solid #e2e5e9;
		box-sizing: border-box;
		box-shadow: 0px 2px 5px rgba(31, 41, 55, 0.15);
		border-radius: 8px;
		color: #374151;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
	}
`;
