import Api from './index';
import { sub, format } from 'date-fns';
// import axios from 'axios';

// post store name
export const postStoreSearchApi = ({
	storeName,
	account,
	offset,
	category,
}) => {
	return Api.post(`/place/list`, {
		search_str: storeName,
		account,
		offset,
		category,
	});
};

// post store distance
export const postStoreDistanceApi = ({ lat, lng, distance, account }) => {
	const bodyParameters = {
		lat,
		lng,
		distance: parseInt(distance),
		account,
	};
	return Api.post(`/place/list`, bodyParameters);
};

// post caltex list
export const postCaltexListApi = ({ lat, lng, account }) => {
	const bodyParameters = {
		lat,
		lng,
		account,
		sub_category_code: 'C4001',
	};
	return Api.post(`/place/list`, bodyParameters);
};

// post store info
export const postStoreInfoApi = ({ id, account, date }) => {
	const futureDate = sub(new Date(), {
		months: date[0],
	});

	const bodyParameters = {
		id,
		start_date: format(futureDate, 'yyyy-MM-dd'),
		end_date: format(new Date(), 'yyyy-MM-dd'),
		account,
	};

	return Api.post(`/place/info`, bodyParameters);
};

/* 리뷰 카드 조회 */
export const getReviewInfoApi = ({ account }) => {
	const bodyParameters = {
		account
	};

	return Api.post(`/review/info`, bodyParameters);
};

/* 로그인 사용자 정보 조회 */
export const getUserInfoApi = ({ account, kor_nm }) => {
	const bodyParameters = {
		account,
		kor_nm,
	};

	return Api.post(`/user/info`, bodyParameters);
};

/* 담당점포/담당OFC 리뷰 등록 현황 요약 조회 */
export const getReviewStatusApi = ({ account }) => {
	const bodyParameters = {
		account
	};

	return Api.post(`/review/status`, bodyParameters);
};