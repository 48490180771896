import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../auth/authConfig';
import styled from 'styled-components';

function handleLogin(instance) {
	instance.loginRedirect(loginRequest).catch((e) => {
		console.error(e);
	});
}

export const SignInButton = () => {
	const { instance } = useMsal();

	return (
		<SignInButtonWrap onClick={() => handleLogin(instance)}>
			<p>로 그 인</p>
		</SignInButtonWrap>
	);
};

const SignInButtonWrap = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 400px;
	padding: 16px 20px;
	background-color: #0472ba;

	p {
		color: #fff;
		font-weight: bold;
		font-size: 16px;
		line-height: 16px;
	}
`;
