import { useState, useRef } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import Box from 'ui-box';

const Select = ({
	placeholder = '',
	value,
	label,
	subLabel,
	children,
	...rest
}) => {
	const ref = useRef(null);
	const [focus, setFocus] = useState(false);

	useClickAway(ref, () => {
		if (focus) setFocus(false);
	});

	return (
		<Box is={SelectWrap} {...rest} focus={focus}>
			{label && (
				<div className='label-wrap'>
					<label>{label}</label>
					{subLabel && <span>{subLabel}</span>}
				</div>
			)}
			<div
				ref={ref}
				className={`select-wrap ${focus ? 'focus' : ''}`}
				onClick={() => {
					setFocus((prev) => !prev);
				}}
			>
				{value ? <p>{value}</p> : <p className='placeholder'>{placeholder}</p>}
				{focus ? <FiChevronUp /> : <FiChevronDown />}

				{focus && <ul>{children}</ul>}
			</div>
		</Box>
	);
};

export default Select;

const SelectWrap = styled.div`
	color: #333;
	.label-wrap {
		display: flex;
		margin-bottom: 8px;
		align-items: center;
		justify-content: space-between;
		span,
		label {
			display: block;
			font-size: 14px;
			color: #757575;
			${({ focus }) => {
				return focus && 'color:#007CFF;';
			}}
		}
	}

	.select-wrap {
		position: relative;
		height: 38px;
		padding: 8px 12px;
		font-size: 14px;
		border: 1px solid #e0e0e0;
		cursor: pointer;
		${({ focus }) => {
			return focus && 'border: 1px solid #007CFF;';
		}}
		border-radius: 5px;
		display: flex;
		align-items: center;
		> p {
			flex: 1;
		}
	}
	ul {
		position: absolute;
		min-width: 100%;
		left: 0;
		top: calc(100% + 8px);
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
		border-radius: 5px;
		background-color: #fff;
		z-index: 100;
	}
	li {
		padding: 8px 12px;
		white-space: nowrap;
	}
	li:hover {
		background: #f5f5f5;
	}
	li.active {
		background: #f5f5f5;
	}
	.placeholder {
		color: #bdbdbd;
	}
`;
